import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { Button, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import Form from "react-bootstrap/Form";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { useState } from 'react';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

function Stock({ toggleDrawer, dataToBeEdited }) {
  const { t, i18n } = useTranslation();
  const [isAddingColor, setIsAddingColor] = useState(false);
  const [isAddingSize, setIsAddingSize] = useState(false);
  const [isAddingOffer, setIsAddingOffer] = useState(false);
  const [newColor, setNewColor] = useState("fff")
  const [data, setData] = useState(undefined);
  const [newOffer, setNewOffer] = useState({
    qty: 0,
    price: 0
  })

  const formik = useFormik({
    initialValues: {
      options: [],
      stock: {
        qty: 0,
        alert_qty: 0
      },
      offers: []
    },
    onSubmit: async (values) => {
      await setDoc(doc(db, "landing-pages", dataToBeEdited.id), {
        ...data,
        options: values.options,
        stock: values.stock,
        offers: values.offers
      }).then(() => {
        notify(t("Update_Completed_Successfully"))
      });
    },
  });

  useEffect(() => {
    // Function to fetch data from an API
    onSnapshot(doc(db, "landing-pages", dataToBeEdited.id), (doc) => {

      formik.setValues({
        options: doc.data().options,
        stock: {
          qty: doc.data().stock.qty,
          alert_qty: doc.data().stock.alert_qty
        },
        offers: doc.data().offers,
      })
      setData(doc.data())
    });
  }, []);

  const notify = async (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleRemoveOffer = (indexToRemove) => {
    const newOffers = formik.values.offers.filter((_, index) => index !== indexToRemove);
    formik.setFieldValue('offers', newOffers);
  };

  return (
    <div className="d-flex flex-column" dir={i18n.language === "ar" ? "rtl" : ""} style={{ minHeight: "100vh" }}>
      <div className="mb-2 d-flex justify-content-between align-items-center" style={{
        minHeight: "30px",
        backgroundColor: "#ECF0F1",
        borderBottom: "2px solid #2C3E50",
      }}>
        <ArrowBackIcon onClick={() => { toggleDrawer(false) }} />
        <p className="m-0 mx-2 p-0">{t("Edit")}</p>
      </div>
      <div className="flex-grow-1 d-flex flex-column">
        <div className="row m-0 p-0 flex-grow-1">
          <div className="col-6">
            <Paper elevation={3} className='p-2' >
              <Form onSubmit={formik.handleSubmit}>
                <h3>{t("Options")}</h3>
                <hr className="m-0 flex-grow-1" />
                {
                  formik.values.options.filter((option) => option.option === "colors").map((option, index) => (
                    <div className="px-2" key={index}>

                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="p-0 m-0 me-2">{t("Colors")}</h6>
                        <div className="py-2 d-flex">
                          {
                            option.values.map((color, index) => (
                              <div
                                key={index}
                                className="rounded rounded-circle me-1"
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  backgroundColor: color,
                                  position: 'relative'
                                }}

                              >
                                <div style={{ position: 'absolute', top: "-20px", left: 0 }} className="text-danger">
                                  {/* Content for the div in the top right */}
                                  <CloseIcon onClick={
                                    () => {


                                      // Find the "colors" option and delete the color at the specified index
                                      let colorsOptionIndex = formik.values.options.findIndex(option => option.option === "colors");

                                      // Check if colors option exists
                                      if (colorsOptionIndex !== -1) {
                                        // Check if the index to delete is valid
                                        console.log(formik.values.options[colorsOptionIndex].values)
                                        // Delete the color at the specified index
                                        formik.values.options[colorsOptionIndex].values.splice(index, 1);
                                        // Update the formik field value with the new options
                                        formik.setFieldValue('options', formik.values.options);

                                      } else {
                                        console.error("Colors option not found in the array.");
                                      }
                                    }
                                  } />
                                </div>
                              </div>
                            ))
                          }
                          {
                            !isAddingColor ?
                              <div
                                onClick={() => { setIsAddingColor(true) }}
                                className="rounded rounded-circle me-1 d-flex justify-content-center align-items-center"
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  backgroundColor: "white",
                                  border: '2px dashed black'
                                }}
                              >
                                <span className='fw-bolder mb-1'>+</span>
                              </div> :
                              <div className='d-flex align-items-center'>
                                <CloseIcon onClick={() => { setIsAddingColor(false) }} />
                                <Form.Control
                                  value={newColor}
                                  onChange={(e) => setNewColor(e.target.value)}
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                  }} className="mx-1 rounded rounded-circle" type="color" />
                                <DoneIcon onClick={() => {

                                  // Assuming 'formik.values.options' is the array you want to modify
                                  let colorsOptionIndex = formik.values.options.findIndex(option => option.option === "colors");
                                  if (colorsOptionIndex !== -1) {

                                    formik.values.options[colorsOptionIndex].values.push(newColor);

                                    setIsAddingColor(false);
                                    setNewColor("#fff")
                                  } else {

                                    console.error("Colors option not found in the array.");
                                  }


                                }
                                } />
                              </div>
                          }
                        </div>
                      </div>

                    </div>
                  ))
                }
                {
                  formik.values.options.filter((option) => option.option !== "colors").map((option, index) => (
                    <div className="px-2" key={index}>

                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="p-0 m-0 me-2">{t("Sizes")}</h6>
                        <div className="py-2 d-flex">
                          {
                            option.values.map((value, index) => (
                              <div
                                key={index}

                                className="px-2 rounded rounded-2 me-1 d-flex justify-content-center align-items-center"
                                style={{
                                  border: formik.values.options[option.option] === value ? '4px solid black' : "1px solid black",
                                  height: "50px",
                                  width: "50px",
                                  position: 'relative'
                                }}
                              >
                                <div style={{ position: 'absolute', top: "-20px", left: 0 }} className="text-danger">
                                  {/* Content for the div in the top right */}
                                  <CloseIcon onClick={
                                    () => {


                                      // Find the "colors" option and delete the color at the specified index
                                      let colorsOptionIndex = formik.values.options.findIndex(option => option.option !== "colors");

                                      // Check if colors option exists
                                      if (colorsOptionIndex !== -1) {
                                        // Check if the index to delete is valid
                                        console.log(formik.values.options[colorsOptionIndex].values)
                                        // Delete the color at the specified index
                                        formik.values.options[colorsOptionIndex].values.splice(index, 1);
                                        // Update the formik field value with the new options
                                        formik.setFieldValue('options', formik.values.options);

                                      } else {
                                        console.error("Colors option not found in the array.");
                                      }
                                    }
                                  } />
                                </div>
                                {value}
                              </div>
                            ))
                          }
                          {!isAddingSize ? <div
                            className="px-2 rounded rounded-2 me-1 d-flex justify-content-center align-items-center"
                            style={{
                              border: '2px dashed black',
                              height: "50px",
                              width: "50px"
                            }}
                            onClick={() => { setIsAddingSize(true) }}
                          >
                            <span className='fw-bolder mb-1'>+</span>

                          </div> : <div className='d-flex align-items-center'>
                            <CloseIcon onClick={() => { setIsAddingSize(false) }} />
                            <Form.Control
                              value={newColor}
                              onChange={(e) => setNewColor(e.target.value)}
                              style={{
                                height: "50px",
                                width: "100px",
                              }} className="mx-1 rounded rounded-circle" type="text" />
                            <DoneIcon onClick={() => {

                              // Assuming 'formik.values.options' is the array you want to modify
                              let colorsOptionIndex = formik.values.options.findIndex(option => option.option !== "colors");
                              if (colorsOptionIndex !== -1) {

                                formik.values.options[colorsOptionIndex].values.push(newColor);

                                setIsAddingSize(false);
                                setNewColor("")
                              } else {

                                console.error("Colors option not found in the array.");
                              }


                            }
                            } />
                          </div>}
                        </div>
                      </div>

                    </div>
                  ))
                }
                <div className="text-end my-1">
                  <Button color="success" type="submit" variant="contained">{t("Save")}</Button>
                </div>
              </Form>
            </Paper>

          </div>
          <div className="col-6 ">
            <Paper elevation={3} className='p-2' >
              <Form onSubmit={formik.handleSubmit}>
                <h3>{t("Quantity")}</h3>
                <hr className="m-0" />
                <div className='d-flex justify-content-between '>
                  <TextField
                    required
                    size='small'
                    label={t("Quantity")}
                    type='number'
                    className="w-100 my-2"
                    id="qty"
                    name="qty"
                    onChange={(e) => { formik.setFieldValue("stock", { ...formik.values.stock, qty: e.target.value }) }}
                    onBlur={formik.handleBlur}
                    value={formik.values.stock.qty}
                  />
                </div>
                <div className='d-flex justify-content-between'>

                  <TextField
                    required
                    size='small'
                    label={t("Alert_Quantity")}
                    type='number'
                    className="w-100 my-2"
                    id="alert_qty"
                    name="alert_qty"
                    onChange={(e) => { formik.setFieldValue("stock", { ...formik.values.stock, alert_qty: e.target.value }) }}
                    onBlur={formik.handleBlur}
                    value={formik.values.stock.alert_qty}
                  />
                </div>
                <div className="text-end my-1">
                  <Button color="success" type="submit" variant="contained">{t("Save")}</Button>
                </div>
              </Form>
            </Paper>

          </div>
          <div className="col-6 ">
            <Paper elevation={3} className='p-2' >
              <Form onSubmit={formik.handleSubmit}>
                <h3>{t("Offers")}</h3>
                <hr className="m-0" />
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="center">{t("Quantity")}</TableCell>
                        <TableCell align="center">{t("Price")}</TableCell>
                        <TableCell align="center">{t("Actions")}</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formik.values.offers.map((offer, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">{offer.qty}</TableCell>
                          <TableCell align="center">{offer.price}</TableCell>
                          <TableCell align="center" ><CloseIcon className='bg-danger rounded text-white' onClick={() => { handleRemoveOffer(index) }} /></TableCell>
                        </TableRow>
                      ))}
                      {isAddingOffer && <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {1}
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            id="outlined-basic"
                            value={newOffer.qty}
                            onChange={(e) => { setNewOffer({ ...newOffer, qty: e.target.value }) }}
                            type="number"
                            label="Quantity"
                            className='w-50'
                            size='small'
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            id="outlined-basic"
                            className='w-50'
                            value={newOffer.price}
                            onChange={(e) => { setNewOffer({ ...newOffer, price: e.target.value }) }}
                            type="number"
                            label="Price"
                            size='small'
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell align="center"><DoneIcon onClick={() => { formik.setFieldValue("offers", [...formik.values.offers, newOffer]); setNewOffer({ price: 0, qty: 0 }); setIsAddingOffer(false) }} className='bg-success rounded text-white m-1' /></TableCell>
                      </TableRow>}
                      {!isAddingOffer && <TableRow>
                        <AddToPhotosIcon onClick={() => setIsAddingOffer(true)} className='bg-success rounded text-white m-1' />
                      </TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="text-end my-1">
                  <Button color="success" type="submit" variant="contained">{t("Save")}</Button>
                </div>
              </Form>
            </Paper>

          </div>
        </div>

      </div>

    </div>
  );
}

export default Stock;
