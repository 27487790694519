
import { Button } from "@mui/material";
import Basic from "../../layouts/basic/Basic";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import TelegramIcon from '@mui/icons-material/Telegram';
import SchoolIcon from "@mui/icons-material/School";
import { useTranslation } from 'react-i18next';
import { getAuth } from "firebase/auth";

function Home() {
  const auth = getAuth();
  const { t, i18n } = useTranslation();

  const telegramChannelLink = "https://web.telegram.org/a/#-1002141318782"; // Replace 'YourTelegramChannel' with your actual Telegram channel username or ID

  const handleJoinTelegram = () => {
    window.open(telegramChannelLink, "_blank");
  };

  console.log(auth)

  return (
    <Basic page="Home">
      <h3>{t('Welcome')}, {auth?.currentUser?.displayName} !</h3>
      {/*<div style={{
        backgroundColor: "#ECF0F1",
        border: "1px solid #2C3E50",
      }}
        className="rounded row m-0 p-0 py-3 mb-3"
      >
        <div className="">
          <img
            src={
              "https://dokean.fra1.digitaloceanspaces.com/Untitled%20design.png"
            }
            alt={"logo"}
            className=" rounded rounded-3"
            height={150}
          />
        </div>
     
      </div>
      */}
      <div className="row m-0 p-0">

        <div className="col-sm-4 col-12 my-1 m-0 p-0 " >

          <div className="row m-0 p-0 rounded d-flex justify-content-between" style={{
            border: "1px solid #2C3E50",
          }}>
            <div className="col-4" style={{ backgroundColor: "#ECF0F1", borderRadius: "0 20px 20px 0" }}>
              <AdsClickIcon
                className="mx-auto" style={{
                  height: "100px",
                  width: "100px",
                }} />
            </div>

            <div className="col-8 d-flex flex-column justify-content-center align-items-end">
              <p className="m-0 p-0 mb-2">{t('Creat_Profissonal_Landing_Page')}</p>
              <Button color="success" size="small" type="submit" variant="contained">{t('Start')}</Button>
            </div>
          </div>

        </div>

        <div className="col-sm-4 col-12 my-1 m-0 p-0 " >
          <div className="row mx-sm-1 mx-0 m-0 p-0 rounded d-flex justify-content-between" style={{
            border: "1px solid #2C3E50",
          }}>
            <div className="col-4 " style={{ backgroundColor: "#ECF0F1", borderRadius: "0 20px 20px 0" }}>
              <SchoolIcon
                className="mx-auto"
                style={{
                  height: "100px",
                  width: "100px",
                }} />
            </div>

            <div className="col-8 d-flex flex-column justify-content-center align-items-end">
              <p className="m-0 p-0 mb-2">{t('Tutorial_About_Our_Platform')}</p>
              <Button color="success" size="small" type="submit" variant="contained">{t('Learn_More')}</Button>
            </div>
          </div>

        </div>

        <div className="col-sm-4 col-12 my-1 row m-0 p-0  rounded d-flex justify-content-between" style={{
          border: "1px solid #2C3E50",
        }}>
          <div className="col-4 " style={{ backgroundColor: "#ECF0F1", borderRadius: "0 20px 20px 0" }}>
            <TelegramIcon 
            className="mx-auto"
            style={{
              height: "100px",
              width: "100px",
            }} />
          </div>

          <div className="col-8 d-flex flex-column justify-content-center align-items-end">
            <p className="m-0 p-0 mb-2">{t('Join_Our_Telegram_Channel')}</p>
            <Button color="success" size="small" type="submit" variant="contained" onClick={handleJoinTelegram}>{t('Join')}</Button>
          </div>
        </div>
      </div>
    </Basic>
  );
}

export default Home;
