/* eslint-disable */
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/user/user";
import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { getAuth, updateProfile } from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";

function Signup() {
  const auth = getAuth();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
      password: "",
      confirm_password: "",
      deleted: false,
      balance: 100,
      user: ""
    },
    onSubmit: (values) => {
      // Handle form submission (e.g., send a request to your API)
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then(async (userCredential) => {
          await addDoc(collection(db, "Account"), {
            balance: values.balance,
            email: values.email,
            user: userCredential.user.uid,
            deleted: values.deleted
          }).then(() => {
            updateProfile(auth.currentUser, {
              displayName: values.fullName
            });
          });
          const user = userCredential.user;
          dispatch(loginUser(user.uid));
          localStorage.setItem("token", user.uid);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <div
      className="App d-flex justify-content-between align-items-center flex-column py-3"
      style={{ backgroundColor: "#ECF0F1" }}
      dir={i18n.language === "ar" ? "rtl" : ""}
    >
      <div className="d-flex">
        <div

          className="rounded rounded-circle me-1 d-flex justify-content-center align-items-center"
          style={{
            height: "35px",
            width: "35px",
            backgroundColor: i18n.language === "en" ? "#2C3E50" : "white",
            position: 'relative',
            border: "2px solid #2C3E50"
          }}
          onClick={() => { handleChangeLanguage("en") }}
        >
          <span style={{ color: i18n.language === "en" ? "white" : "#2C3E50" }}>EN</span>
        </div>
        <div

          className="rounded rounded-circle me-1 d-flex justify-content-center align-items-center"
          style={{
            height: "35px",
            width: "35px",
            backgroundColor: i18n.language === "fr" ? "#2C3E50" : "white",
            position: 'relative',
            border: "2px solid #2C3E50"
          }}
          onClick={() => { handleChangeLanguage("fr") }}
        >
          <span style={{ color: i18n.language === "fr" ? "white" : "#2C3E50" }}>FR</span>
        </div>
        <div

          className="rounded rounded-circle me-1 d-flex justify-content-center align-items-center"
          style={{
            height: "35px",
            width: "35px",
            backgroundColor: i18n.language === "ar" ? "#2C3E50" : "white",
            position: 'relative',
            border: "2px solid #2C3E50"
          }}
          onClick={() => { handleChangeLanguage("ar") }}
        >
          <span className="fw-blod" style={{ marginTop: "-5px", color: i18n.language === "ar" ? "white" : "#2C3E50" }}>ع</span>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center w-100">
        <div className="d-flex justify-content-center">
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/eco-landing-79b1b.appspot.com/o/LOGO%20(1).png?alt=media&token=d42ae268-b7c2-4f9e-b065-877193f22b72"
            }
            alt={"logo"}
            className=" rounded rounded-3 mb-4"
            height={30}
          />
        </div>
        <div className=" p-4 col-sm-3 col-10" dir="RTL">
          <Form onSubmit={formik.handleSubmit}>

            <>
              <Form.Group className="mb-3">
                <TextField
                  placeholder=""
                  id="fullName"
                  name="fullName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  label={t('Full_Name')}
                  fullWidth
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <TextField
                  placeholder="Enter email"
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  label={t('Email')}
                  fullWidth
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <TextField
                  placeholder="Password"
                  type="password"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  label={t('Password')}
                  fullWidth
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <TextField
                  placeholder="confirm password"
                  type="password"
                  id="confirm_password"
                  name="confirm_password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirm_password}
                  label={t('Confirm_Password')}
                  fullWidth
                />
              </Form.Group>
              <div className="text-start">
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  style={{ backgroundColor: "#2c3e50" }}
                >
                  {" "}
                  <span className="fw-bolder fs-6">{t('Login')}</span>

                </Button>
              </div>
              <hr />
              <p>
                {t('Do_You_Have_Account')}{" "}
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to={`/login`}
                >
                  <b>{t('Login_Now')}</b>
                </Link>
              </p>
            </>

          </Form>
        </div>
      </div>

      <div >
        2024 © dokean
      </div>
    </div>
  );
}

export default Signup;
