import React, { useEffect, useState, useRef, useMemo } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { AccordionDetails, AccordionSummary, Box, Tab, Tabs, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import PageEditor from "../../../components/design";
import { Accordion } from "react-bootstrap";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Form from "react-bootstrap/Form";

function Design({ toggleDrawer, dataToBeEdited }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const iframeRef = useRef(null);
  const handleLoad = () => {
    // Once the iframe content is loaded, set loading to false
    setLoading(false);
  };
  const [currentTap, setCurrentTap] = useState("design")
  useEffect(() => {
    // Function to fetch data from an API
    onSnapshot(doc(db, "landing-pages", dataToBeEdited.id), (doc) => {
      setData({
        id: dataToBeEdited.id,
        ...doc.data()
      })
    });
  }, []);

  const [isAddingColor, setIsAddingColor] = useState(false);
  const [newColor, setNewColor] = useState("#000")

  const [rows, setRows] = useState([
    {
      name: "S",
      qty: 10,
      colors: ["red", "black", "yellow"]
    },
    {
      name: "L",
      qty: 8,
      colors: ["yellow"]
    }
  ]);

  const [indexToEdit, setIndexToEdit] = useState(0)

  const addColorToRow = (name, newColor) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.name === name
          ? { ...row, colors: [...row.colors, newColor] }
          : row
      )
    );
    setNewColor("#000")
  };

  const deleteRowByIndex = (indexToDelete) => {
    setRows((prevRows) => prevRows.filter((_, index) => index !== indexToDelete));
  };


  return (
    <>
      {!!data && <div dir={i18n.language === "ar" ? "rtl" : ""} className="d-flex flex-column" style={{ minHeight: "100vh", minWidth: "100vw" }}>
        <div className=" d-flex align-items-center" style={{
          height: "5vh",
          backgroundColor: "#2C3E50",
          borderBottom: "2px solid #2C3E50",
        }}>
          <ArrowBackIcon className="text-white me-2" onClick={() => { toggleDrawer(false) }} />
          <p className="text-white p-0 m-0">{" "}Back</p>
        </div>

        <div className="flex-grow-1 row m-0 p-0">

          <div className="col-sm-5 col-12" style={i18n.language === "ar" ? { borderLeft: "3px dashed black", backgroundColor: "#F3F7FD" } : { borderRight: "1px solid   black", backgroundColor: "#F3F7FD" }}>
            {/*<h4 className="">{t('Design_Your_Landing_Page')} &#128071;</h4>*/}
            <div style={{ height: "95vh", }}>
              <Box className="" sx={{ backgroundColor: "#F3F7FD", height: "5vh", borderBottom: "2px solid black" }}>
                <Tabs
                  //value={value}
                  // onChange={handleChange}
                  className=" w-100 my-2"
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                >
                  <Tab onClick={() => setCurrentTap("design")} label="Design" style={currentTap === "design" ? { backgroundColor: "#2C3E50", color: "white" } : {}} />
                  <Tab onClick={() => setCurrentTap("stock")} label="Stock" style={currentTap === "stock" ? { backgroundColor: "#2C3E50", color: "white" } : {}} />
                  <Tab onClick={() => setCurrentTap("delevery")} label="Delevery" style={currentTap === "delevery" ? { backgroundColor: "#2C3E50", color: "white" } : {}} />
                  <Tab onClick={() => setCurrentTap("offers")} label="Offers" style={currentTap === "offers" ? { backgroundColor: "#2C3E50", color: "white" } : {}} />
                </Tabs>
              </Box>
              <Box className="custom-scrollbar p-2" sx={{ overflow: "auto", height: "90vh" }}>
                {currentTap === "design" && <PageEditor dataToBeEdited={dataToBeEdited} setLoading={setLoading} data={data} setData={setData} iframeRef={iframeRef} />}
                {currentTap === "stock" && <>
                  <Accordion className="my-3 rounded rounded-4 bg-white" defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                      style={{ backgroundColor: "#2C3E50" }}
                      aria-controls="ceo"
                      id="ceo"
                      className="rounded rounded-4"
                    >
                      <Typography className="text-white fw-bold fs-6">{t('Options')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="">
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell >Name</TableCell>
                              <TableCell >Qty</TableCell>
                              <TableCell >Colors</TableCell>
                              <TableCell >actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell >{row.name}</TableCell>
                                <TableCell >{row.qty}</TableCell>
                                <TableCell >
                                  <div className="d-flex align-items-center">
                                    {row.colors.map((color, index) => (
                                      <div
                                        key={index}
                                        className="rounded rounded-circle me-1"
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          backgroundColor: color,
                                        }}
                                      />
                                    ))}
                                    {
                                      !isAddingColor ?
                                        <div
                                          onClick={() => { setIsAddingColor(true); setIndexToEdit(index) }}
                                          className="rounded rounded-circle me-1 d-flex justify-content-center align-items-center"
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            backgroundColor: "white",
                                            border: '2px dashed black'
                                          }}
                                        >
                                          <span className='fw-bolder'>+</span>
                                        </div> :
                                        indexToEdit === index && <div className='d-flex align-items-center'>
                                          <CloseIcon onClick={() => { setIsAddingColor(false) }} />
                                          <Form.Control
                                            value={newColor}
                                            onChange={(e) => setNewColor(e.target.value)}
                                            style={{
                                              height: "30px",
                                              width: "30px",
                                            }} className="mx-1 rounded rounded-circle" type="color" />
                                          <DoneIcon onClick={() => addColorToRow(row.name, newColor)} />
                                        </div>
                                    }
                                  </div>

                                </TableCell>
                                <TableCell >
                                  <div
                                    style={{ height: "30px", width: "30px" }}
                                    className="rounded rounded-circle text-white"

                                  >
                                    {/* Content for the div in the top right */}
                                    <DeleteIcon className="text-danger" onClick={() => deleteRowByIndex(index)} />
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </>}
              </Box>

            </div>
          </div>
          <div className="col-sm-7 col-12 d-flex">

            <div className="flex-grow-1 d-flex justify-content-center align-items-center">

              <div
                className="d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "black", width: "450px", height: "850px", borderRadius: "15px" }}>
                <div style={{ backgroundColor: "white", width: "420px", height: "800px", borderRadius: "15px" }}>
                  <iframe
                    ref={iframeRef}
                    title="External Site"
                    src={`https://${dataToBeEdited.sub_domain}.dokean.store`}
                    className="mx-auto"
                    style={{ borderRadius: "10px", width: "100%", height: "100%", display: loading ? "none" : "", border: "1px solid black" }}
                    onLoad={handleLoad}
                  />
                  {loading && (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                      <CircularProgress />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}

export default Design;
