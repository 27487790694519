
import Basic from "../../layouts/basic/Basic";

function Shipping() {
  return (
    <Basic page={"Shipping"}>
      
    </Basic>
  );
}

export default Shipping;