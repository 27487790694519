import NavBar1 from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import HomeIcon from "@mui/icons-material/Home";
import HelpIcon from "@mui/icons-material/Help";
import { useTranslation } from 'react-i18next';

function Basic({ page, children }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="App  d-flex flex-column"  >
      <NavBar1 />

      <main
        className="flex-grow-1 px-3 d-flex flex-column"
        style={{ paddingTop: "75px" }}
        dir={i18n.language === "ar" ? "rtl" : ""}
      >
        <div className="d-flex align-items-center justify-content-between"  >
          <div className="d-flex align-items-center">
            <HomeIcon
              style={{
                color: "#2C3E50",
              }}
            />
            <p className="fs-6 m-0 p-0">{t(page)}</p>
          </div>
          <HelpIcon
            style={{
              color: "green",
            }}
          />
        </div>

        <hr className="m-0 mt-1 mb-2" />
        <div className="flex-grow-1 d-flex flex-column">{children}</div>
      </main>
      <Footer />
    </div>
  );
}

export default Basic;
