import "./App.css";
import Login from "./pages/login/login";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./pages/home/home";
import Categories from "./pages/categories/categories";
import Orders from "./pages/orders/orders";
import Settings from "./pages/settings/settings";
import Signup from "./pages/signup/singup";
import Support from "./pages/support/support";
import Center from "./pages/center/center";
import Landings from "./pages/products/landing";
import Shipping from "./pages/shipping/shipping";
import Subscription from "./pages/subscription/subscription";
import { db } from "./firebase";
import { useDispatch } from 'react-redux';
import { fetchStore } from "./redux/store/store";
import { useState } from "react";

function App() {
  const dispatch = useDispatch();
  const auth = getAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn'));

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|BlackBerry/.test(navigator.userAgent);
  };

  onAuthStateChanged(auth, user => {
    if (user) {
      const unsub2 = onSnapshot(
        query(collection(db, "Account"), where("user", "==", user.uid), where("deleted", "==", false)),
        snapShot => {
          let list = [];
          snapShot.docs.forEach(doc => {
            list.push({ id: doc.id, ...doc.data() });
          });
          dispatch(fetchStore(list[0]));
        }
      );
      setIsLoggedIn("true")
      localStorage.setItem('isLoggedIn', true);
    } else {
      setIsLoggedIn("false")
      localStorage.setItem('isLoggedIn', false);
    }
  });


  return (
    <>
      {!isMobileDevice() ? <BrowserRouter>
        <Routes >
          <Route
            path="*"
            element={<Navigate to={localStorage.getItem('isLoggedIn') === "true" ? "/home" : "/login"} />}
          />
          {isLoggedIn === "true" ? (
            <>
              <Route path="/home" element={<Home />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/Landings" element={<Landings />} />
              <Route path="/Landings/:landingsId" element={<Orders />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/Support" element={<Support />} />
              <Route path="/Center" element={<Center />} />
              <Route path="/Shipping" element={<Shipping />} />
              <Route path="/Subscription" element={<Subscription />} />

            </>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              {<Route path="/signup" element={<Signup />} />}
            </>
          )}
        </Routes>
      </BrowserRouter> :
        <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center fs-2">
          💻 Please Enter using PC 🖥️
        </div>}
    </>
  );
}

export default App;
