function Footer() {
  return (
    <footer
      className="text-center py-2 fw-bolder fst-italic text-white"
      style={{ backgroundColor: "#2C3E50", borderTop: "2px solid #2C3E50" }}
    >
      2024 © dokean
    </footer>
  );
}

export default Footer;
