import { Button, Link, TextField } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useEffect, useState } from "react";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useTranslation } from 'react-i18next';


function FacebookPixel({ toggleDrawer, dataToBeEdited }) {

  const [value, setValue] = useState("");
  const [data, setData] = useState("");
  const [addedValue, setAddedValue] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Function to fetch data from an API
    onSnapshot(doc(db, "landing-pages", dataToBeEdited.id), (doc) => {
      console.log(doc.data().apis)
      setValue(doc.data().apis.facebook_pixel)
      setData(doc.data())
    });
  }, []);

  return (
    <div className="d-flex flex-column" dir={i18n.language === "ar" ? "rtl" : ""} style={{ minHeight: "100vh" }}>
      <div className="mb-2 d-flex justify-content-between" style={{
        minHeight: "30px",
        backgroundColor: "#ECF0F1",
        borderBottom: "2px solid #2C3E50",
      }}>
        <ArrowBackIcon onClick={() => { toggleDrawer(false) }} />
        <p className="m-0 p-0">Facebook Pixel Api</p>
      </div>
      <div className=" d-flex flex-column">
        <div className="row m-0 p-0 flex-grow-1" style={{ height: "100%" }}>
          <div className="col-6 d-flex flex-column">
            <FacebookIcon
              style={{
                width: "100px",
                height: "100px",
                color: "#2C3E50",
                marginLeft: "-13px"
              }}
            />
            <h3 >Facebook Pixel Api</h3>
            <p>
            {t("Facebook_Pixel_Api_des")}
            </p>
            <div>
              <TextField id="outlined-basic" size="small" label="Facebook Pixel Id" variant="outlined" fullWidth disabled={!!value} value={!!value ? value : addedValue} onChange={(e) => { setAddedValue(e.target.value) }}/>
              <div className="text-end py-2">
                {!value ? <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={async () => {
                    await setDoc(doc(db, "landing-pages", dataToBeEdited.id), {
                      ...data,
                      apis: {
                        ...data.apis,
                        facebook_pixel: addedValue
                      }
                    }).then(() => {

                    });
                  }}
                >{t("Link")}</Button> :
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={async () => {
                      // Handle form submission (e.g., send a request to your API)
                      await setDoc(doc(db, "landing-pages", dataToBeEdited.id), {
                        ...data,
                        apis: {
                          ...data.apis,
                          facebook_pixel: ""
                        }
                      }).then(() => {

                      });
                    }}
                  >{t("Remove")}</Button>}
              </div>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-center align-items-center">
            <iframe
              title="YouTube Video"
              width="350"
              height="200" // Adjust height as needed
              src="https://www.youtube.com/embed/VIDEO_ID" // Replace VIDEO_ID with the ID of your YouTube video
              style={{
                border: "2px solid black" // Adjust color and style as needed
              }}
            />
          </div>
        </div>
      </div>

    </div>
  );
}

export default FacebookPixel;
