const LOGIN_USER = "LOGIN_USER";
const LOGOUT_USER = "LOGOUT_USER";

const initialState = {
  user: localStorage.getItem("token")
};

export const loginUser = payload => ({
  type: LOGIN_USER,
  payload
});

export const logoutUser = payload => ({
  type: LOGOUT_USER,
  payload
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, user: action.payload };
    case LOGOUT_USER:
      return { ...state, user: "" };
    default:
      return state;
  }
};

export default reducer;
