/* eslint-disable */
const Fetch_PRODUCTS = "Fetch_PRODUCTS";

const initialState = [];

export const fetchProducts = payload => ({
  type: Fetch_PRODUCTS,
  payload
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Fetch_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
