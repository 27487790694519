import Basic from "../../layouts/basic/Basic";
import { useState, useEffect } from "react";
import { db } from "../../firebase";
import Drawer from "@mui/material/Drawer";
import {
  Avatar,
  Button,
  CardContent,
  CardHeader,
  CardMedia,
  FormControl,
  FormHelperText,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
} from "@mui/material";

import Card from '@mui/material/Card';
import DrawTwoToneIcon from '@mui/icons-material/DrawTwoTone';
import { getAuth } from "firebase/auth";
import { collection, onSnapshot, query, where, setDoc, doc, getDocs, addDoc, serverTimestamp } from "firebase/firestore";
import Design from "./landings/design";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Delevery from "./landings/delevery";
import FacebookPixel from "./landings/facebookPixel";
import GoogleSheet from "./landings/googleSheet";
import Stock from "./landings/stock";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from "react-toastify";
import { landingPageDemo } from "./landings/landingPageData";
function Landings() {
  const { t, i18n } = useTranslation();

  const store = useSelector((state) => state.storeReduce);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newLandingPage, setNewLandingPage] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [DrawerValue, setDrawerValue] = useState(null);
  const [isAddingNewLandingPage, setIsAddingNewLandingPage] = useState(false)
  const [isAddingNewLandingError, setIsAddingNewLandingError] = useState(false)

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };
  const open = Boolean(anchorEl);
  const [dataToBeEdited, setDataToBeEdited] = useState({ id: "" });
  const handleClick = (event, item) => {
    setDataToBeEdited(item)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    //setDataToBeEdited({ id: "" })
  };
  const auth = getAuth();
  useEffect(() => {
    // Function to fetch data from an API
    if (!!auth.currentUser) {

      const unsub2 = onSnapshot(
        query(collection(db, "landing-pages"), where("user", "==", auth.currentUser.uid), where("deleted", "==", false)),
        snapShot => {
          let list = [];
          snapShot.docs.forEach(doc => {
            list.push({ id: doc.id, ...doc.data() });
          });
          setDate(list)
        }
      );
    }

  }, [auth.currentUser]);

  const [data, setDate] = useState([]);


  const timestampToDateString = (timestamp) => {
    let dateObject;

    // Check if timestamp is null or timestamp.seconds is null
    if (!timestamp || timestamp.seconds == null) {
      // If timestamp is null or timestamp.seconds is null, use the current date
      dateObject = new Date();
    } else {
      // Convert Firebase timestamp to JavaScript Date object
      dateObject = new Date(timestamp.seconds * 1000);
    }

    // Array of month names
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Get month, day, and year
    const month = months[dateObject.getMonth()];
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
  };

  return (
    <Basic page="Landing_Page">
      <ToastContainer />
      {/*add filter section here */}
      <div className="row m-0 p-0">
        {data.map((item) => (
          <div key={item.id} className="col-sm-3 col-12 mb-3">
            <Card className="rounded rounded-5 shadow-lg" style={{ filter: item.disable && "grayscale(100%)", border: "1px solid #2C3E50", backgroundColor: "#F3F7FD" }}>
              <CardHeader
                avatar={
                  <Avatar style={{ width: "10px", height: "10px" }} width={5} aria-label="recipe" className={!item.disable && "text-success bg-success"} />
                }
                action={
                  <div>
                    <DrawTwoToneIcon
                      id={`basic-button-${item.id}`}
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e) => handleClick(e, item)}
                      className="text-white"
                    />
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={() => { setDrawerValue("Edit"); setOpenDrawer(true); handleClose() }}>{t('Edit')}</MenuItem>
                      <MenuItem onClick={() => { setDrawerValue("Location"); setOpenDrawer(true); handleClose() }} >{t('Location')}</MenuItem>
                      <MenuItem onClick={() => { setDrawerValue("facebook_Pixel"); setOpenDrawer(true); handleClose() }} >{t('Facebook_Api')}</MenuItem>
                      <MenuItem onClick={() => { setDrawerValue("Google_Sheet"); setOpenDrawer(true); handleClose() }} >{t('GoogleSheet_Api')}</MenuItem>
                      <MenuItem onClick={() => { setDrawerValue("Stock"); setOpenDrawer(true); handleClose() }} >{t('Stock_Mangment')}</MenuItem>
                      <MenuItem onClick={async () => {
                        await setDoc(doc(db, "landing-pages", dataToBeEdited.id), {
                          ...dataToBeEdited,
                          disable: !dataToBeEdited.disable
                        }).then(() => {
                          handleClose()
                        })
                      }} >{dataToBeEdited.disable ? t("Enable") : t("Disable")}</MenuItem>
                      <MenuItem className="text-danger" onClick={async () => {
                        await setDoc(doc(db, "landing-pages", dataToBeEdited.id), {
                          ...item,
                          deleted: true
                        }).then(() => {
                          handleClose()
                        })
                      }}>{t('Delete')}</MenuItem>
                    </Menu>
                  </div>
                }
                title={<a href={`https://${item.sub_domain}.dokean.store`} target="_blank">{`https://${item.sub_domain}.dokean.store`}</a>}
                subheader={<p className="text-white p-0 m-0">{timestampToDateString(item.created_at)}</p>}
                style={{ borderBottom: "1px solid #2C3E50", backgroundColor: "#2C3E50" }}
              />
              <div className="d-flex justify-content-center py-4">
                <img src={item.ceo.logo} style={{
                  objectFit: "contain", borderRadius: "50%", width: "250px",
                  height: "250px", border: "1px solid #2C3E50"
                }} />
              </div>
              <CardContent style={{ borderTop: "1px solid #2C3E50", backgroundColor: "#2C3E50" }} className="text-white d-flex justify-content-between align-items-center">
                <div>
                  <div className="d-flex">
                    <VisibilityIcon />
                    <p className="m-0 p-0 ms-1"> {item.visits}</p>
                  </div>
                </div>

                <div>
                  <Link
                    style={{ color: "inherit" }}
                    to={`/Landings/${item.id}`}
                    className="col-2 fw-blod"
                  >
                    {t('Orders')}
                  </Link>

                </div>

              </CardContent>
            </Card>
          </div>
        ))
        }
        {!isAddingNewLandingPage ?
          <div className="col-sm-3 col-12 d-flex flex-column mb-2" style={{ height: "330px" }} >
            <div className="d-flex justify-content-center align-items-center rounded flex-grow-1" style={{ border: '2px dashed black' }}>

              <span style={{ cursor: "pointer" }} onClick={() => {
                if (store.balance >= 20) {
                  setIsAddingNewLandingPage(true)
                } else {
                  toast.error(t('Balance_Is_Not_Enough'), {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                }

              }}>{t("Add_New_Landing_Page")}</span>
              {" "}
              <div className="d-flex align-items-center ms-2">
                <b className="me-2">{20}</b>
                <div className="coin mt-1" style={{ width: "15px", height: "15px" }}>
                  <div className="coin-face">
                    <div className="coin-symbol">D</div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          : <Paper elevation={3} className="col-sm-3 col-12 d-flex flex-column rounded" >
            <div className="d-flex justify-content-end">
              <div onClick={() => { setIsAddingNewLandingPage(false) }} className="d-flex justify-content-center align-items-center mt-2" style={{ height: "25px", width: "25px", backgroundColor: "red", color: "white", borderRadius: "50%" }}>X</div>
            </div>
            <div className="flex-grow-1 d-flex align-items-center">
              <FormControl variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  error={isAddingNewLandingError}
                  value={newLandingPage}
                  onChange={(e) => { setNewLandingPage(e.target.value) }}
                  endAdornment={<InputAdornment position="end">.dokean.store</InputAdornment>}
                  startAdornment={<InputAdornment position="end">https://</InputAdornment>}
                  size="small"
                />
                {isAddingNewLandingError && <FormHelperText className="text-danger">{t("This_Subdomain_Already_Exists")}</FormHelperText>}
              </FormControl>
            </div>
            <div className="d-flex justify-content-end mb-2">
              <Button color="success" size="small" variant="contained" onClick={async () => {
                setIsAddingNewLandingError(false)
                const q = query(collection(db, "landing-pages"), where("sub_domain", "==", newLandingPage));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                  setIsAddingNewLandingError(true)

                } else {
                  await setDoc(doc(db, "Account", store.id), {
                    ...store,
                    balance: parseInt(store.balance - 20)
                  }).then(async () => {
                    await addDoc(collection(db, "landing-pages"), { ...landingPageDemo, user: auth.currentUser.uid, visits: 0, sub_domain: newLandingPage, created_at: serverTimestamp() }).then(() => {
                      setIsAddingNewLandingPage(false);
                      setNewLandingPage("")
                    });
                  });


                }
              }}>Save</Button>
            </div>
          </Paper>}
      </div>
      <Drawer open={openDrawer} anchor={"bottom"} onClose={toggleDrawer(false)}>
        {!!dataToBeEdited.id && DrawerValue === "Edit" && <Design toggleDrawer={setOpenDrawer} dataToBeEdited={dataToBeEdited} />}
        {!!dataToBeEdited.id && DrawerValue === "Location" && <Delevery toggleDrawer={setOpenDrawer} dataToBeEdited={dataToBeEdited} />}
        {!!dataToBeEdited.id && DrawerValue === "facebook_Pixel" && <FacebookPixel toggleDrawer={setOpenDrawer} dataToBeEdited={dataToBeEdited} />}
        {!!dataToBeEdited.id && DrawerValue === "Google_Sheet" && <GoogleSheet toggleDrawer={setOpenDrawer} dataToBeEdited={dataToBeEdited} />}
        {!!dataToBeEdited.id && DrawerValue === "Stock" && <Stock toggleDrawer={setOpenDrawer} dataToBeEdited={dataToBeEdited} />}

      </Drawer>
    </Basic>
  );
}

export default Landings;
