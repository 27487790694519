
import { Button, Chip, Divider, Paper } from "@mui/material";
import Basic from "../../layouts/basic/Basic";
import { useTranslation } from 'react-i18next';

function Subscription() {
  const { t, i18n } = useTranslation();
  return (
    <Basic page={"Subscription"}>
      <div className="row m-0 p-0">
        <div className="col-sm-3 col-12">
          <Paper elevation={3} className='p-2 my-2' >
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">100 {t('Coin')}</span>
              </div>
              <Chip label="DZD 500" color="success" />
            </div>
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">10 {t('Coin')}</span>
              </div>
              <Chip label="DZD 0" color="success" />
            </div>
            <Divider className="my-2" />
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">110 {t('Coin')}</span>
              </div>
              <Chip label="DZD 500" color="success" />
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button size="small" variant="outlined" color="success">
                {t('Checkout')}
              </Button>
            </div>
          </Paper>
        </div>

        <div className="col-sm-3 col-12">
          <Paper elevation={3} className='p-2 my-2' >
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">200 {t('Coin')}</span>
              </div>
              <Chip label="DZD 1000" color="success" />
            </div>
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">25 {t('Coin')}</span>
              </div>
              <Chip label="DZD 0" color="success" />
            </div>
            <Divider className="my-2" />
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">225 {t('Coin')}</span>
              </div>
              <Chip label="DZD 1000" color="success" />
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button size="small" variant="outlined" color="success">
              {t('Checkout')}
              </Button>
            </div>
          </Paper>
        </div>

        <div className="col-sm-3 col-12">
          <Paper elevation={3} className='p-2 my-2' >
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">400 {t('Coin')}</span>
              </div>
              <Chip label="DZD 2000" color="success" />
            </div>
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">50 {t('Coin')}</span>
              </div>
              <Chip label="DZD 0" color="success" />
            </div>
            <Divider className="my-2" />
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">450 {t('Coin')}</span>
              </div>
              <Chip label="DZD 2000" color="success" />
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button size="small" variant="outlined" color="success">
              {t('Checkout')}
              </Button>
            </div>
          </Paper>
        </div>

        <div className="col-sm-3 col-12">
          <Paper elevation={3} className='p-2 my-2' >
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">1000 {t('Coin')}</span>
              </div>
              <Chip label="DZD 5000" color="success" />
            </div>
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">100 {t('Coin')}</span>
              </div>
              <Chip label="DZD 0" color="success" />
            </div>
            <Divider className="my-2" />
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex align-items-center">
                <div class="coin">
                  <div class="coin-face">
                    <div class="coin-symbol">D</div>
                  </div>
                </div>
                <span className="mx-2">1100 {t('Coin')}</span>
              </div>
              <Chip label="DZD 5000" color="success" />
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button size="small" variant="outlined" color="success">
              {t('Checkout')} 
              </Button>
            </div>
          </Paper>
        </div>

      </div>

    </Basic>
  );
}

export default Subscription;