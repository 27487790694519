import { Button, Chip, TextField } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import DescriptionIcon from '@mui/icons-material/Description';
import { useEffect, useState } from "react";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";

function GoogleSheet({ toggleDrawer, dataToBeEdited }) {
  const [value, setValue] = useState("");
  const [data, setData] = useState("");
  const [addedValue, setAddedValue] = useState("");
  useEffect(() => {
    // Function to fetch data from an API
    onSnapshot(doc(db, "landing-pages", dataToBeEdited.id), (doc) => {
      setValue(doc.data().apis.google_sheet)
      setData(doc.data())
    });
  }, []);
  const { t, i18n } = useTranslation();

  const url = 'https://script.google.com/macros/s/AKfycbyovzBpQCvicAS0ouQEeMiY-nVyA1e6t4aDGVOffVR71QnG1d0Up85SRGPib7xDChLA/exec';
  const body = {
    "first_name": "first_name",
    "last_name": "last_name",
    "phone": "phone",
    "state": "state",
    "sub_state": "sub_state",
    "address": "address",
    "home_delevery": "home_delevery",
    "product_name": "product_name",
    "product_id": "product_id",
    "options": "options",
    "qty": "qty",
    "price": "price",
    "price_delevery": "price_delevery",
    "total": "total"
  };
  const handleClick = () => {
    const textToCopy = `function doPost(e) {
    try {
      // Open the active spreadsheet and the active sheet
      var spreadsheet = SpreadsheetApp.getActiveSpreadsheet();
      var sheet = spreadsheet.getActiveSheet();
      // Get the last row with data in the sheet
      var lastRow = sheet.getLastRow();
  
      // Get the current date
      var currentDate = new Date();
  
      // Format the date as per your requirement
      var formattedDateTime = Utilities.formatDate(currentDate, Session.getScriptTimeZone(), 'MM/dd/yyyy HH:mm:ss');
  
      var user = JSON.parse(e.postData.contents);
      // Prepare the data to be added
      var data = [
        [formattedDateTime, user.first_name, user.last_name, user.phone, user.state, user.sub_state, user.address, user.home_delevery, user.product_name, user.product_id,user.options, user.qty, user.price, user.price_delevery, user.total]
      ];
  
      // Add the data to the next empty row in the sheet
      sheet.getRange(lastRow + 1, 1, 1, data[0].length).setValues(data);
  
      return ContentService.createTextOutput(JSON.stringify({status: "success"})).setMimeType(ContentService.MimeType.JSON);
    } catch (error) {
      // Log the error
      console.error(error);
      // Return an error response
      return ContentService.createTextOutput("An error occurred: " + error.message).setStatusCode(500);
    }
  }`;

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log("Function copied to clipboard successfully");
      })
      .catch((error) => {
        console.error("Error copying function to clipboard:", error);
      });
  };
  return (
    <div className="d-flex flex-column" style={{ minHeight: "100vh" }} dir={i18n.language === "ar" ? "rtl" : ""}>
      <div className="mb-2 d-flex justify-content-between align-items-center" style={{
        minHeight: "30px",
        backgroundColor: "#ECF0F1",
        borderBottom: "2px solid #2C3E50",
      }}>
        <ArrowBackIcon onClick={() => { toggleDrawer(false) }} />
        <p className="m-0 px-2 p-0">Google sheet Api</p>
      </div>
      <div className="flex-grow-1 d-flex flex-column">
        <div className="row m-0 p-0 ">
          <div className="col-6 d-flex flex-column ">
            <DescriptionIcon
              style={{
                width: "100px",
                height: "100px",
                color: "#2C3E50",
                marginLeft: "-13px"
              }}
            />
            <h3>Google sheet Api</h3>
            <p>
              {t("Google_sheet_Api_des")}
            </p>
            <div className="">
              <Chip label={t("Copy_Library_ID")} variant="outlined" onClick={handleClick} />
            </div>
            <Divider className="my-3" />
            <div>
              <TextField id="outlined-basic" disabled={!!value} value={!!value ? value : addedValue} onChange={(e) => { setAddedValue(e.target.value) }} size="small" label="URL" variant="outlined" fullWidth />
              <div className="text-end py-2">
                {!value ? <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={async () => {
                    await fetch(addedValue, {
                      redirect: "follow",
                      method: "POST",
                      body: JSON.stringify(body),
                      headers: {
                        "Content-Type": "text/plain;charset=utf-8",
                      },
                    }).then(async (response) => {
                      if (response.ok) {
                        // Handle form submission (e.g., send a request to your API)
                        await setDoc(doc(db, "landing-pages", dataToBeEdited.id), {
                          ...data,
                          apis: {
                            ...data.apis,
                            google_sheet: addedValue
                          }
                        }).then(() => {

                        });
                      }
                    })
                  }}
                >{t("Link")}</Button> :
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={async () => {
                      // Handle form submission (e.g., send a request to your API)
                      await setDoc(doc(db, "landing-pages", dataToBeEdited.id), {
                        ...data,
                        apis: {
                          ...data.apis,
                          google_sheet: ""
                        }
                      }).then(() => {

                      });
                    }}
                  >{t("Remove")}</Button>}
              </div>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-center align-items-center">
            <iframe
              title="YouTube Video"
              width="350"
              height="200" // Adjust height as needed
              src="https://www.youtube.com/embed/VIDEO_ID" // Replace VIDEO_ID with the ID of your YouTube video
              style={{
                border: "2px solid black" // Adjust color and style as needed
              }}
            />
          </div>
        </div>
      </div>

    </div >
  );
}

export default GoogleSheet;
