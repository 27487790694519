import { Button, TextField } from "@mui/material";
import Basic from "../../layouts/basic/Basic";
import { useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { db } from "../../firebase";
import { ToastContainer, toast } from "react-toastify";

function Support() {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  const formik = useFormik({
    initialValues: {
      full_name: "",
      phone: "",
      email: "",
      message: "",
    },
    onSubmit: async (values) => {
      // Handle form submission (e.g., send a request to your API)
      addDoc(collection(db, "support"), { ...values, created_at: serverTimestamp() }).then(() => {
        formik.resetForm();
        notify(t("Message_Sent_Successfully"))
      })
    },
  });

  const notify = async (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <Basic page={"Support"}>
      <ToastContainer />
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <div className="col-sm-4 col-12 d-flex flex-column ">
          <div className="d-flex justify-content-center">
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/eco-landing-79b1b.appspot.com/o/LOGO%20(1).png?alt=media&token=d42ae268-b7c2-4f9e-b065-877193f22b72"
              }
              alt={"logo"}
              className=" rounded rounded-3 my-2"
              height={30}
            />
          </div>
          <Form onSubmit={formik.handleSubmit} className="row m-0">
            <TextField
              id="full_name"
              name="full_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.full_name}
              variant="outlined"
              size="small"
              label={t('Full_Name')}
              className="my-2"
            />

            <TextField
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              variant="outlined"
              size="small"
              label={t('Phone_Number')}
              className="my-2"
            />
            <TextField
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              variant="outlined"
              size="small"
              label={t('Email')}
              className="my-2"
            />
            <TextField
              id="message"
              name="message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
              variant="outlined"
              size="small"
              label={t('Message')}
              className="my-2"
              multiline
              rows={4}
            />
            <Button type="submit" variant="contained" className="bg-success my-2">
              {t('Send')}
            </Button>
          </Form>

        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>{t('Support_Message')}</Modal.Body>

      </Modal>
    </Basic>
  );
}

export default Support;
