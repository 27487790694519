import Basic from "../../layouts/basic/Basic";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import { collection, onSnapshot, query, where, setDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase";
import { Button, Checkbox, Chip, Divider, FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Modal from 'react-bootstrap/Modal';
import PrintIcon from '@mui/icons-material/Print';
import { useFormik } from 'formik';
import { Form } from "react-bootstrap";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Timestamp } from 'firebase/firestore'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from "react-toastify";

function Orders() {
  const auth = getAuth();
  const store = useSelector((state) => state.storeReduce);

  const { landingsId } = useParams();

  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const [dataToShow, setDataToShow] = useState(null);
  const handleClose = () => setShow(false);

  const [data, setDate] = useState([]);
  const handlePhoneIconClick = (phone) => {
    window.location.href = `tel:${phone}`;
  };
  useEffect(() => {

    if (!!auth.currentUser) {
      // Function to fetch data from an API
      const unsub2 = onSnapshot(
        query(collection(db, "landing-pages", landingsId, "orders"), where("user", "==", auth.currentUser.uid), where("is_deleted", "==", false)),
        snapShot => {
          let list = [];
          snapShot.docs.forEach(doc => {
            list.push({ id: doc.id, ...doc.data() });
          });
          setDate(list)
        }
      );
    }

  }, [auth.currentUser]);

  const formik = useFormik({
    initialValues: {

      status: "",
      date: "",
      start_date: "",

    },
    onSubmit: async (values) => {
      const current_timestamp = Timestamp.fromDate(new Date());
      // Calculate the timestamp for 24 hours ago
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
      const twentyFourHoursAgoTimestamp = Timestamp.fromDate(twentyFourHoursAgo);

      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // Subtract 7 days
      const oneWeekAgoTimestamp = Timestamp.fromDate(oneWeekAgo);

      // Calculate the timestamp for a month ago (approximately 30 days)
      const oneMonthAgo = new Date();
      oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
      const oneMonthAgoTimestamp = Timestamp.fromDate(oneMonthAgo);

      const unsub2 = onSnapshot(

        values.status !== "" ?
          query(
            collection(db, "landing-pages", landingsId, "orders"),
            where("user", "==", auth.currentUser.uid),
            where("is_deleted", "==", false),
            where("status_delevery", "==", values.status),
            where('created_at', values.date !== "" ? '>' : '<',
              values.date === "day" ? twentyFourHoursAgoTimestamp :
                values.date === "week" ? oneWeekAgoTimestamp :
                  values.date === "month" ? oneMonthAgoTimestamp :
                    current_timestamp
            )
          )
          :
          query(
            collection(db, "landing-pages", landingsId, "orders"),
            where("user", "==", auth.currentUser.uid),
            where("is_deleted", "==", false),
            where('created_at', values.date !== "" ? '>' : '<',
              values.date === "day" ? twentyFourHoursAgoTimestamp :
                values.date === "week" ? oneWeekAgoTimestamp :
                  values.date === "month" ? oneMonthAgoTimestamp :
                    current_timestamp
            )
          ),



        snapShot => {
          let list = [];
          snapShot.docs.forEach(doc => {
            list.push({ id: doc.id, ...doc.data() });
          });
          setDate(list)
        }
      );
    },
  });

  return (
    <Basic page="Orders">
      <ToastContainer />
      <Paper style={{ borderColor: "black", borderWidth: "1px", borderStyle: "solid" }} elevation={3} className='p-2 my-2' >
        <Form onSubmit={formik.handleSubmit} className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <FormControl sx={{ minWidth: 120 }} className=" p-0">
              <InputLabel sx={{ marginTop: "-5px" }} id="status-label">{t("Status")}</InputLabel>
              <Select
                size="small"
                labelId="status-label"
                style={{
                  width: "150px", backgroundColor: formik.values.status === "new" ? "#FFA500" :
                    formik.values.status === "preparing_for_shipping" ? "#FFD700" :
                      formik.values.status === "shipping_in_progress" ? "#87CEEB" :
                        formik.values.status === "delivered" ? "#00FF00" :
                          formik.values.status === "completed" ? "#9370DB" :
                            formik.values.status === "returned" ? "#FF0000" :
                              "",
                }}
                id="status"
                name="status"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.status}
                className="mx-2"
                label="Age"
              >
                <MenuItem style={{ backgroundColor: "#FFA500" }} value={"new"}>{t('New')}</MenuItem>
                <MenuItem style={{ backgroundColor: "#FFD700" }} value={"preparing_for_shipping"}>{t('Preparing_For_Shipping')}</MenuItem>
                <MenuItem style={{ backgroundColor: "#87CEEB" }} value={"shipping_in_progress"}>{t('Shipping_In_Progress')}</MenuItem>
                <MenuItem style={{ backgroundColor: "#00FF00" }} value={"delivered"}>{t('Delivered')}</MenuItem>
                <MenuItem style={{ backgroundColor: "#9370DB" }} value={"completed"}>{t('Completed')}</MenuItem>
                <MenuItem style={{ backgroundColor: "#FF0000" }} value={"returned"}>{t('Returned')}</MenuItem>

              </Select>
            </FormControl>



            <Chip
              variant="outlined"
              style={{ backgroundColor: formik.values.date === "day" && "#2C3E50", color: formik.values.date === "day" && "white" }}
              className="me-1"
              label={t("Hours_24")}
              onClick={() => { formik.setFieldValue("date", "day") }}
            />
            <Chip
              variant="outlined"
              style={{ backgroundColor: formik.values.date === "week" && "#2C3E50", color: formik.values.date === "week" && "white" }}
              className="me-1"
              label={t("Days_7")}
              onClick={() => { formik.setFieldValue("date", "week") }}
            />
            <Chip
              variant="outlined"
              style={{ backgroundColor: formik.values.date === "month" && "#2C3E50", color: formik.values.date === "month" && "white" }}
              className="me-1"
              label={t("Month")}
              onClick={() => { formik.setFieldValue("date", "month") }}
            />
            <div className="d-flex align-items-center ms-2">
              <span>{t("From-This_Date")}</span>
              <input
                id="end_date"
                name="end_date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.end_date}
                type="date"
                className="ms-2"
              />
            </div>

            <Button type="button" onClick={() => {
              formik.resetForm()
              const unsub2 = onSnapshot(
                query(collection(db, "landing-pages", landingsId, "orders"), where("user", "==", auth.currentUser.uid), where("is_deleted", "==", false)),
                snapShot => {
                  let list = [];
                  snapShot.docs.forEach(doc => {
                    list.push({ id: doc.id, ...doc.data() });
                  });
                  setDate(list)
                }
              );
            }} size="small" className="mx-2" variant="outlined"><RestartAltIcon /></Button>
          </div>

          <Button type="submit" size="small" className="mx-2" variant="outlined"><FindInPageIcon /></Button>
        </Form>
      </Paper>
      {data.length < 1 ? <div style={{ borderColor: "black", borderWidth: "1px", borderStyle: "dashed" }} className="flex-grow-1 d-flex justify-content-center align-items-center my-2 rounded-2">
        <p className="fs-2">{t("No_available_Data")}</p>
      </div>
        :
        <>

          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="table-cell"><Checkbox /></TableCell>
                  <TableCell className="table-cell">{t("Id")}</TableCell>
                  <TableCell className="table-cell" align="center">{t("Actions")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("Full_Name")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("Phone")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("Date")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("Status")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("State")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("Commune")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("Address")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("Quantity")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("Price")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("Home_Delivery")}</TableCell>
                  <TableCell className="table-cell" align="center" style={{ whiteSpace: "nowrap" }}>{t("Price_Delivery")}</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow
                    key={item.id}
                    style={{
                      backgroundColor:
                        item.status_delevery === "new" ? "rgba(255, 165, 0, 0.2)" :
                          item.status_delevery === "preparing_for_shipping" ? "rgba(255, 215, 0, 0.2)" :
                            item.status_delevery === "shipping_in_progress" ? "rgba(135, 206, 235, 0.2)" :
                              item.status_delevery === "delivered" ? "rgba(0, 255, 0, 0.2)" :
                                item.status_delevery === "completed" ? "rgba(147, 112, 219, 0.2)" :
                                  item.status_delevery === "returned" ? "rgba(255, 0, 0, 0.2)" :
                                    ""
                    }}
                  >
                    <TableCell className="table-cell"  ><Checkbox /></TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" component="th" scope="row">

                      <Tooltip
                        title={
                          <>
                            {item.id}
                          </>
                        }
                      >
                        <span style={{ display: "inline-block", width: "50px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                          {item.id}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center"><CloseIcon onClick={async () => {
                      await setDoc(doc(db, "landing-pages", landingsId, "orders", item.id), {
                        ...item,
                        is_deleted: true
                      }).then(() => {

                      });
                    }} className='bg-danger rounded text-white' />
                      <ReceiptLongIcon onClick={() => {
                        setDataToShow(item);
                        setShow(true)
                      }} className='bg-info-subtle rounded ms-1 border border-2 border-black' /></TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">{`${item.first_name} ${item.last_name}`}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">
                      {item.paid ? <>
                        <WifiCalling3Icon className='bg-success mx-2 p-1 rounded-circle text-white' onClick={() => { handlePhoneIconClick(item.phone) }} />
                        <span className="mt-1">{item.phone}</span>
                      </> : <LockOpenIcon onClick={async (e) => {
                        if (store.balance > 0) {
                          await setDoc(doc(db, "Account", store.id), {
                            ...store,
                            balance: parseInt(store.balance - 1)
                          }).then(async () => {
                            await setDoc(doc(db, "landing-pages", landingsId, "orders", item.id), {
                              ...item,
                              paid: true
                            }).then(() => {

                            });
                          });

                        } else {
                          toast.error(t('Balance_Is_Not_Enough'), {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                        }


                      }
                      } />}

                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">
                      {item && item.created_at ? new Date(item.created_at.seconds * 1000 + item.created_at.nanoseconds / 1000000).toLocaleString() : "No date available"}
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">
                      <>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          size="small"
                          disabled={item.status_delevery === "completed"}
                          style={{
                            width: "150px", backgroundColor: item.status_delevery === "new" ? "#FFA500" :
                              item.status_delevery === "preparing_for_shipping" ? "#FFD700" :
                                item.status_delevery === "shipping_in_progress" ? "#87CEEB" :
                                  item.status_delevery === "delivered" ? "#00FF00" :
                                    item.status_delevery === "completed" ? "#9370DB" :
                                      item.status_delevery === "returned" ? "#FF0000" :
                                        ""
                          }}
                          onChange={async (e) => {

                            await setDoc(doc(db, "landing-pages", landingsId, "orders", item.id), {
                              ...item,
                              status_delevery: e.target.value
                            }).then(() => {

                            });
                          }
                          }
                          value={item.status_delevery}
                        >

                          <MenuItem style={{ backgroundColor: "#FFA500" }} value={"new"}>{t('New')}</MenuItem>
                          <MenuItem style={{ backgroundColor: "#FFD700" }} value={"preparing_for_shipping"}>{t('Preparing_For_Shipping')}</MenuItem>
                          <MenuItem style={{ backgroundColor: "#87CEEB" }} value={"shipping_in_progress"}>{t('Shipping_In_Progress')}</MenuItem>
                          <MenuItem style={{ backgroundColor: "#00FF00" }} value={"delivered"}>{t('Delivered')}</MenuItem>
                          <MenuItem style={{ backgroundColor: "#9370DB" }} value={"completed"}>{t('Completed')}</MenuItem>
                          <MenuItem style={{ backgroundColor: "#FF0000" }} value={"returned"}>{t('Returned')}</MenuItem>

                        </Select>
                      </>
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">{item.state}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">{item.sub_state}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">{item.address}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">{item.qty}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">{item.price}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">{item.home_delevery ? "yes" : "no"}</TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }} className="table-cell" align="center">{item.price_delevery}</TableCell>

                  </TableRow>
                ))}


              </TableBody>
            </Table>
          </TableContainer>
          <>
            <Modal size="lg" show={show} onHide={handleClose} centered>
              <Modal.Header style={{
                backgroundColor:
                  dataToShow?.status_delevery === "new" ? "rgba(255, 165, 0, 0.2)" :
                    dataToShow?.status_delevery === "preparing_for_shipping" ? "rgba(255, 215, 0, 0.2)" :
                      dataToShow?.status_delevery === "shipping_in_progress" ? "rgba(135, 206, 235, 0.2)" :
                        dataToShow?.status_delevery === "delivered" ? "rgba(0, 255, 0, 0.2)" :
                          dataToShow?.status_delevery === "completed" ? "rgba(147, 112, 219, 0.2)" :
                            dataToShow?.status_delevery === "returned" ? "rgba(255, 0, 0, 0.2)" :
                              ""
              }}>
                <Modal.Title className="d-flex justify-content-between align-items-center w-100">
                  <Chip label={`Order ID: ${dataToShow?.id}`} />
                  <span>
                    {dataToShow && dataToShow.created_at ? new Date(dataToShow.created_at.seconds * 1000 + dataToShow.created_at.nanoseconds / 1000000).toLocaleString() : "No date available"}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{
                backgroundColor:
                  dataToShow?.status_delevery === "new" ? "rgba(255, 165, 0, 0.2)" :
                    dataToShow?.status_delevery === "preparing_for_shipping" ? "rgba(255, 215, 0, 0.2)" :
                      dataToShow?.status_delevery === "shipping_in_progress" ? "rgba(135, 206, 235, 0.2)" :
                        dataToShow?.status_delevery === "delivered" ? "rgba(0, 255, 0, 0.2)" :
                          dataToShow?.status_delevery === "completed" ? "rgba(147, 112, 219, 0.2)" :
                            dataToShow?.status_delevery === "returned" ? "rgba(255, 0, 0, 0.2)" :
                              ""
              }}>
                <div className="d-flex justify-content-between px-4">
                  <span className="fw-bolder">Full Name</span>
                  <span>{dataToShow?.first_name + " " + dataToShow?.last_name}</span>
                </div>
                <div className="d-flex justify-content-between px-4">
                  <span className="fw-bolder">Phone</span>
                  <span>{dataToShow?.phone}</span>
                </div>
                <div className="d-flex justify-content-between px-4">
                  <span className="fw-bolder">State</span>
                  <span>{dataToShow?.state}</span>
                </div>
                <div className="d-flex justify-content-between px-4">
                  <span className="fw-bolder">Commune</span>
                  <span>{dataToShow?.sub_state}</span>
                </div>
                <div className="d-flex justify-content-between px-4">
                  <span className="fw-bolder">Address</span>
                  <span>{dataToShow?.address}</span>
                </div>
                <Divider className="my-2" />
                <div className="d-flex justify-content-between px-4">
                  <span className="fw-bolder">Quantity</span>
                  <span>{dataToShow?.qty}</span>
                </div>
                <div className="d-flex justify-content-between px-4">
                  <span className="fw-bolder">Price</span>
                  <span>{dataToShow?.price}</span>
                </div>
                <div className="d-flex justify-content-between px-4">
                  <span className="fw-bolder">Delevery Price</span>
                  <span>{dataToShow?.price_delevery}</span>
                </div>
                <div className="d-flex justify-content-between px-4">
                  <span className="fw-bolder">Home Delevery</span>
                  <span>{dataToShow?.home_delevery ? "yes" : "no"}</span>
                </div>
                <div className="d-flex justify-content-between px-4">
                  <span className="fw-bolder">Total</span>
                  <span className="fw-bolder">{(parseInt(dataToShow?.qty) * parseInt(dataToShow?.price) + parseInt(dataToShow?.price_delevery))}</span>
                </div>
              </Modal.Body>
              <Modal.Footer style={{
                backgroundColor:
                  dataToShow?.status_delevery === "new" ? "rgba(255, 165, 0, 0.2)" :
                    dataToShow?.status_delevery === "preparing_for_shipping" ? "rgba(255, 215, 0, 0.2)" :
                      dataToShow?.status_delevery === "shipping_in_progress" ? "rgba(135, 206, 235, 0.2)" :
                        dataToShow?.status_delevery === "delivered" ? "rgba(0, 255, 0, 0.2)" :
                          dataToShow?.status_delevery === "completed" ? "rgba(147, 112, 219, 0.2)" :
                            dataToShow?.status_delevery === "returned" ? "rgba(255, 0, 0, 0.2)" :
                              ""
              }}>
                <CloseIcon onClick={handleClose} style={{ width: "50px" }} className='bg-danger rounded text-white' />
                <PrintIcon onClick={handleClose} style={{ width: "50px" }} className='bg-info-subtle rounded ms-1 border border-2 border-black' />
              </Modal.Footer>
            </Modal>
          </>
        </>}
    </Basic>
  );
}

export default Orders;
