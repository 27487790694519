import Form from "react-bootstrap/Form";
import ReactQuill from "react-quill";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Tab, Tabs, TextField } from "@mui/material";
import { useFormik } from "formik";
import DeleteIcon from '@mui/icons-material/Delete';
import { debounce } from 'lodash';
import ImageCompressor from 'react-image-file-resizer';
import axios from 'axios';
import CachedIcon from '@mui/icons-material/Cached';
import { useTranslation } from 'react-i18next';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";

function PageEditor({ dataToBeEdited, data, setData, iframeRef }) {

    const { t, i18n } = useTranslation();
    const editorRef = useRef();
    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const auth = getAuth();
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };
    const handleButtonClick2 = () => {
        fileInputRef2.current.click();
    };
    const handleButtonClick3 = () => {
        fileInputRef3.current.click();
    };

    const handleFileChange = (event, type) => {

        const file = event.target.files[0];
        if (file) {

            uploadImage22([file], type)
        }


    };

    const uploadImage22 = async (e, type) => {
        let count = 0;
        for (let i = 0; i < e.length; i++) {

            await ImageCompressor.imageFileResizer(
                e[i], // input file
                Infinity, // max width
                Infinity, // max height
                'WEBP', // output format
                80, // quality
                0, // rotation
                async (uri) => {
                    const name = new Date().getTime() + i + "main";
                    const storageRef = ref(storage, `${auth.currentUser.uid}/product/${dataToBeEdited.id}/${type}/${name}`);
                    const uploadTask = uploadBytesResumable(storageRef, uri);
                    uploadTask.on(
                        "state_changed",
                        snapshot => {
                            // Observe state change events such as progress, pause, and resume
                            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                            const progress =
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log("Upload is " + progress + "% done");
                            switch (snapshot.state) {
                                case "paused":
                                    console.log("Upload is paused");
                                    break;
                                case "running":
                                    console.log("Upload is running");
                                    break;
                            }
                        },
                        error => {
                            // Handle unsuccessful uploads
                        },
                        () => {
                            // Handle successful uploads on complete
                            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {

                                if (type === "logo") {
                                    formik.setFieldValue("logo", downloadURL)
                                }
                                if (type === "image") {
                                    formik.setFieldValue("image", downloadURL)
                                }
                                if (type === "slider") {

                                    formik.setFieldValue("slider", [...formik.values.slider, downloadURL])
                                }
                            });
                        }
                    );

                },
                'file' // output type
            );

        }
    };
    const uploadImage = async (e, type) => {

        for (let i = 0; i < e.length; i++) {
            // Resize the image using ImageCompressor
            await ImageCompressor.imageFileResizer(
                e[i], // input file
                Infinity, // max width
                Infinity, // max height
                'WEBP', // output format
                80, // quality
                0, // rotation
                async (uri) => {
                    const dataImage = new FormData();
                    const blob = await fetch(uri).then((r) => r.blob());
                    dataImage.append('upload', blob, new Date().getTime() + i + 'main');

                    axios.post(`https://urchin-app-rniix.ondigitalocean.app/upload`, dataImage, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    })
                        .then(function (response) {

                            try {
                                if (type === "logo") {
                                    formik.setFieldValue("logo", response.data.url)
                                }
                                if (type === "image") {
                                    formik.setFieldValue("image", response.data.url)
                                } else {

                                    formik.setFieldValue("slider", [...formik.values.slider, response.data.url])
                                }



                                console.log("User information updated successfully!");
                            } catch (error) {
                                console.error("Error updating user information:", error);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                },
                'base64' // output type
            );
        }
    };


    const [value, setValue] = useState('');

    const refreshIframe = () => {
        if (iframeRef.current) {
            iframeRef.current.src = `https://${dataToBeEdited.sub_domain}.dokean.store`
        }
    }
    useEffect(() => {
        // Function to fetch data from an API
        onSnapshot(doc(db, "landing-pages", dataToBeEdited.id), (doc) => {
            formik.setValues({
                title: doc.data().title,
                bio: doc.data().bio,
                review: doc.data().review,
                price: doc.data().price,
                image: doc.data().image,
                currency: doc.data().currency,
                discount: doc.data().discount,
                brand_name: doc.data().brand_name,

                footer: doc.data().footer,
                facebook: doc.data().socialMedia.facebook,
                instagram: doc.data().socialMedia.instagram,
                tiktok: doc.data().socialMedia.tiktok,
                phone: doc.data().socialMedia.phone,
                slider: doc.data().slider,
                title_ceo: doc.data().ceo.title,
                description_ceo: doc.data().ceo.description,
                logo: doc.data().ceo.logo,
                visits: doc.data().visits,
            })
            setValue(doc.data().description)
        });
    }, []);



    const formik = useFormik({
        initialValues: {
            brand_name: "",
            title: "",
            bio: "",
            review: "",
            price: 0,
            image: "",
            currency: "",
            discount: 0,

            footer: "",
            facebook: "",
            instagram: "",
            tiktok: "",
            phone: "",

            description: "",
            slider: [],

            title_ceo: "",
            description_ceo: "",
            logo: ""
        },
        onSubmit: async (values) => {
            // Handle form submission (e.g., send a request to your API)
            await setDoc(doc(db, "landing-pages", dataToBeEdited.id), {
                ...data,
                title: values.title,
                bio: values.bio,
                review: values.review,
                price: values.price,
                image: values.image,
                currency: values.currency,
                discount: values.discount,
                brand_name: values.brand_name,
                footer: values.footer,
                socialMedia: {
                    facebook: values.facebook,
                    instagram: values.instagram,
                    tiktok: values.tiktok,
                    phone: values.phone,
                },
                description: value,

                slider: values.slider,
                ceo: {
                    title: values.title_ceo,
                    description: values.description_ceo,
                    logo: values.logo,
                },
                visits: parseInt(values.visits) - 1
            }).then(() => {
                refreshIframe()
            });
        },
    });

    const imageHandler = async (e) => {

        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                uploadImage2(file)
            } else {
                console.warn("You could only upload images.");
            }
        };
    }

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" },
                    ],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                    [{ color: [] }, { background: [] }],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        }),
        []
    );



    const uploadImage2 = async (file) => {
        // Resize the image using ImageCompressor

        await ImageCompressor.imageFileResizer(
            file, // input file
            Infinity, // max width
            Infinity, // max height
            'webp', // output format
            80, // quality
            0, // rotation
            async (uri) => {
                // If you convert base64 to blob
                const response = await fetch(uri);
                const blob = await response.blob();
                const name = new Date().getTime() + "description";
                const storageRef = ref(storage, `${auth.currentUser.uid}/product/${dataToBeEdited.id}/${"description"}/${name}`);
                const uploadTask = uploadBytesResumable(storageRef, blob);
                uploadTask.on(
                    "state_changed",
                    snapshot => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress =
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log("Upload is " + progress + "% done");
                        switch (snapshot.state) {
                            case "paused":
                                console.log("Upload is paused");
                                break;
                            case "running":
                                console.log("Upload is running");
                                break;
                        }
                    },
                    error => {
                        // Handle unsuccessful uploads
                    },
                    () => {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                            console.log(downloadURL)
                            const editor = editorRef.current.getEditor();
                            editor.insertEmbed(editor.getSelection(), "image", downloadURL);
                        });
                    }
                );

            },

            'base64' // output type
        );
    }

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "background",
        "align",
    ];




    const debouncedUpdateBackend = debounce(async (colorValue, refreshIframe, color) => {
        if (color === "color1") {
            await setDoc(doc(db, "landing-pages", data.id), {
                ...data,
                body: {
                    ...data.body,
                    color1: colorValue
                },
                visits: parseInt(data.visits) - 1
            });
        }
        if (color === "color2") {
            await setDoc(doc(db, "landing-pages", data.id), {
                ...data,
                body: {
                    ...data.body,
                    color2: colorValue
                },
                visits: parseInt(data.visits) - 1
            });
        }
        if (color === "color3") {
            await setDoc(doc(db, "landing-pages", data.id), {
                ...data,
                body: {
                    ...data.body,
                    color3: colorValue
                },
                visits: parseInt(data.visits) - 1
            });
        }
        refreshIframe();
    }, 3000);
    return (
        <>
            <Accordion className="my-3 rounded rounded-4" defaultExpanded>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                    aria-controls="page-Design"
                    id="Page-Design"
                    className="rounded rounded-4"
                    style={{ backgroundColor: "#2C3E50" }}
                >
                    <Typography className="text-white fw-bold fs-6">{t('Page_Design')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="d-flex justify-content-between align-items-center my-1">
                        <p>{t('Landing_Page_Colors')} :</p>
                        <div className="d-flex">
                            <Form.Control value={data.body.color1} onChange={(e) => {
                                debouncedUpdateBackend(e.target.value, refreshIframe, "color1");
                            }

                            } className="mx-1 rounded rounded-circle" type="color" placeholder="Normal text" />
                            <Form.Control value={data.body.color2} onChange={(e) => {
                                debouncedUpdateBackend(e.target.value, refreshIframe, "color2");
                            }} className="mx-1 rounded rounded-circle" type="color" placeholder="Normal text" />
                            <Form.Control value={data.body.color3} onChange={async (e) => {
                                debouncedUpdateBackend(e.target.value, refreshIframe, "color3");
                            }} className="mx-1 rounded rounded-circle" type="color" placeholder="Normal text" />

                        </div>

                    </div>
                    <div className="d-flex justify-content-between align-items-center my-1">
                        <p>{t('Language')} :</p>
                        <div>
                            <FormControl >
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={data.lang}
                                    name="radio-buttons-group"
                                    onChange={async (e) => {
                                        await setDoc(doc(db, "landing-pages", data.id), {
                                            ...data,
                                            lang: e.target.value,
                                            visits: parseInt(data.visits) - 1
                                        }).then(() => {
                                            refreshIframe();
                                        });
                                    }}

                                >
                                    <div className="d-flex">
                                        <FormControlLabel value="ar" control={<Radio />} label={t('Arabic')} />
                                        <FormControlLabel value="en" control={<Radio />} label={t('English')} />
                                        <FormControlLabel value="fr" control={<Radio />} label={t('French')} />
                                    </div>

                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>

                </AccordionDetails>
            </Accordion>
            <Accordion className="my-3 rounded rounded-4" defaultExpanded>
                <AccordionSummary
                    className="rounded rounded-4"
                    aria-controls="product-info"
                    id="product-info"
                    expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                    style={{ backgroundColor: "#2C3E50" }}
                >
                    <Typography className="text-white fw-bold fs-6">{t('Product_Info')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Form onSubmit={formik.handleSubmit} className="row m-0">
                        <TextField
                            label={t('Heander')}
                            className="col-12 my-1"
                            id="brand_name"
                            name="brand_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.brand_name}
                        />
                        <TextField
                            label={t('Product_Name')}
                            className="col-8 my-1"
                            id="title"
                            name="title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                        />
                        <TextField
                            label={t('Price')}
                            className="col-4 my-1 ps-2"
                            id="price"
                            name="price"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.price}
                        />
                        <TextField
                            label={t('Product_Bio')}
                            className="w-100 my-1"
                            id="bio"
                            name="bio"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bio}
                        />
                        <TextField
                            label={t('Review')}
                            className="col-4 my-1"
                            id="review"
                            name="review"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.review}
                        />
                        <TextField
                            label={t('Currency')}
                            className="col-4 my-1 ps-1"
                            id="currency"
                            name="currency"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.currency}
                        />
                        <TextField
                            label={t('Discount')}
                            className="col-4 ps-1 my-1"
                            id="discount"
                            name="discount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.discount}
                        />


                        <Divider />
                        <div className="p-0 m-0">
                            <h6> {t('Product_Image')}</h6>
                            <div style={{ position: "relative", display: "inline-block" }}>
                                <img
                                    className="border border-1 rounded"
                                    src={formik.values.image}
                                    width={200}
                                    height={200}
                                    alt="product Image"
                                />
                                <div style={{ position: "absolute", top: 0, right: 0 }}>
                                    {/* Your icon component or HTML here */}
                                    <>
                                        <input
                                            id="image"
                                            ref={fileInputRef}
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => { handleFileChange(e, "image") }}
                                        />
                                        <div onClick={handleButtonClick}>
                                            < CachedIcon className="mx-1" />
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className="text-end">
                            <Button type="submit" variant="contained" className="bg-success">{t('Save')}</Button>
                        </div>
                    </Form>
                </AccordionDetails>
            </Accordion>
            <Accordion className="my-3 rounded rounded-4" defaultExpanded>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                    style={{ backgroundColor: "#2C3E50" }}
                    aria-controls="description"
                    id="description"
                    className="rounded rounded-4"
                >
                    <Typography className="text-white fw-bold fs-6">{t('Description')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Form onSubmit={formik.handleSubmit}>
                        <ReactQuill
                            theme="snow"
                            style={{ height: "100%" }}
                            value={value}
                            ref={editorRef}
                            modules={modules}
                            formats={formats}
                            onChange={(editor) => { setValue(editor) }}
                        />
                        <div className="text-end">
                            <Button type="submit" className="bg-success" variant="contained">{t('Save')}</Button>
                        </div>

                    </Form>

                </AccordionDetails>
            </Accordion>
            <Accordion className="my-3 rounded rounded-4" defaultExpanded>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                    style={{ backgroundColor: "#2C3E50" }}
                    aria-controls="footer"
                    id="footer"
                    className="rounded rounded-4"
                >
                    <Typography className="text-white fw-bold fs-6">{t('Footer')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Form onSubmit={formik.handleSubmit}>
                        <TextField
                            label={t('Footer')}
                            className="w-100 my-1"
                            id="footer"
                            name="footer"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.footer}
                        />
                        <TextField
                            label={t('Facebook_Page_Url')}
                            className="w-100 my-1"
                            id="facebook"
                            name="facebook"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.facebook}
                        />
                        <TextField
                            label={t('Instagram_Page_Url')}
                            className="w-100 my-1"
                            id="instagram"
                            name="instagram"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.instagram}
                        />
                        <TextField
                            label={t('Tiktop_Page_Url')}
                            className="w-100 my-1"
                            id="tiktok"
                            name="tiktok"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.tiktok}
                        />
                        <TextField
                            label={t('Phone')}
                            className="w-100 my-1"
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                        />
                        <div className="text-end mt-2">
                            <Button type="submit" className="bg-success" variant="contained">{t('Save')}</Button>
                        </div>
                    </Form>
                </AccordionDetails>
            </Accordion>
            <Accordion className="my-3 rounded rounded-4" defaultExpanded>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                    style={{ backgroundColor: "#2C3E50" }}
                    aria-controls="slider"
                    id="slider"
                    className="rounded rounded-4"
                >
                    <Typography className="text-white fw-bold fs-6">{t('Carousel')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <>
                        <input
                            id="slider-input"
                            ref={fileInputRef3}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { handleFileChange(e, "slider") }}
                        />
                        <Button
                            className=""
                            type="button"
                            onClick={handleButtonClick3}
                            variant="contained"
                        >
                            +
                        </Button>
                    </>
                    <div className="row">
                        {formik.values.slider.map((image1, index) => (
                            <div key={index} className="col-4 my-1" style={{ position: 'relative' }} >
                                <img src={image1} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                <div
                                    style={{ position: 'absolute', top: 5, right: 7 }}
                                    className="bg-danger rounded rounded-circle text-white"
                                    onClick={() => {
                                        formik.setFieldValue("slider", [
                                            ...formik.values.slider.slice(0, index),
                                            ...formik.values.slider.slice(index + 1)
                                        ]);
                                    }}
                                >
                                    {/* Content for the div in the top right */}
                                    <DeleteIcon />
                                </div>
                            </div>

                        ))}
                    </div>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="text-end">
                            <Button type="submit" className="bg-success" variant="contained">{t('Save')}</Button>
                        </div>
                    </Form>

                </AccordionDetails>
            </Accordion>
            <Accordion className="my-3 rounded rounded-4" defaultExpanded>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                    style={{ backgroundColor: "#2C3E50" }}
                    aria-controls="ceo"
                    id="ceo"
                    className="rounded rounded-4"
                >
                    <Typography className="text-white fw-bold fs-6">{t('Ceo_Info')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Form onSubmit={formik.handleSubmit}>
                        <TextField
                            label={t('Title')}
                            className="w-100 my-1"
                            id="title_ceo"
                            name="title_ceo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title_ceo}
                        />
                        <TextField
                            label={t('Description')}
                            className="w-100 my-1"
                            id="description_ceo"
                            name="description_ceo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description_ceo}
                        />
                        <Divider />
                        <div className=" d-flex flex-column justify-content-start align-items-start" style={{ position: 'relative' }}>


                            <div style={{ position: "relative", display: "inline-block" }}>
                                <img
                                    className="border border-1 rounded"
                                    src={formik.values.logo}
                                    width={200}
                                    height={200}
                                    alt="Your Image"
                                />
                                <div style={{ position: "absolute", top: 0, right: 0 }}>
                                    {/* Your icon component or HTML here */}
                                    <>
                                        <input
                                            ref={fileInputRef2}
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => { handleFileChange(e, "logo") }}
                                        />
                                        <div onClick={handleButtonClick2}>
                                            < CachedIcon className="mx-1" />
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className="text-end">
                            <Button type="submit" variant="contained" className="bg-success">{t('Save')}</Button>
                        </div>
                    </Form>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default PageEditor;
