import { useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import Basic from "../../layouts/basic/Basic";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ImageCompressor from 'react-image-file-resizer';

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FcPlus, FcSearch, FcSettings, FcEmptyTrash } from "react-icons/fc";
import { ToastContainer, toast } from "react-toastify";
import { db, storage } from "../../firebase";

import {
  addDoc,
  collection,
  serverTimestamp,
  setDoc,
  doc
} from "firebase/firestore";

function Categories() {
  const [modalShow, setModalShow] = useState(false);
  const user = useSelector(state => state.userReducer.user);
  const Categories = useSelector(state => state.categoriesReduce);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const notify = async message => {
    await sleep(500);
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored"
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      name_ar: "",
      image: "",
      deleted: false
    },
    onSubmit: values => {
      if (!values.id) {
        addDoc(collection(db, "categories"), {
          ...values,
          user,
          createdAt: serverTimestamp()
        }).then(setModalShow(false), notify("Add Done"));
      } else {
        setDoc(doc(db, "categories", values.id), values).then(
          setModalShow(false),
          notify("Update Done")
        );
      }
      formik.resetForm();
    }
  });
  const actionTemplate = rowData => {
    return (
      <div className="d-flex text-start">
        <div
          style={{
            height: "40px",
            width: "40px"
          }}
          className="bg-info rounded-circle me-2 d-flex justify-content-center align-items-center"
        >
          <FcSettings
            size={30}
            onClick={() => {
              setModalShow(true);
              formik.setValues(rowData);
            }}
          />
        </div>
        <div
          style={{
            height: "40px",
            width: "40px"
          }}
          className="bg-danger rounded-circle d-flex justify-content-center align-items-center"
        >
          <FcEmptyTrash size={30} onClick={() => handleDelete(rowData)} />
        </div>
      </div>
    );
  };

  const imageBodyTemplate = rowData => {
    return (
      <img
        src={`${rowData.image}`}
        height="75px"
        onError={e =>
          (e.target.src = "https://foorweb.net/product-foorweb.png")
        }
        alt={rowData.image}
        className="product-image"
      />
    );
  };

  const dateBodyTemplate = rowData => {
    if (!!rowData.createdAt) {
      const date = new Date(rowData.createdAt.seconds * 1000);
      return <span>{date.toLocaleString().toString()}</span>;
    }
  };

  const handleDelete = async data => {
    setDoc(doc(db, "categories", data.id), { ...data, deleted: true }).then(
      notify("Delete Done")
    );
  };

  const uploadImage = async (e, type) => {
    for (let i = 0; i < e.length; i++) {

      await ImageCompressor.imageFileResizer(
        e[i], // input file
        Infinity, // max width
        Infinity, // max height
        'WEBP', // output format
        80, // quality
        0, // rotation
        async (uri) => {
          const name = new Date().getTime() + i + "main";
          console.log(uri)
          const storageRef = ref(storage, name);
          const uploadTask = uploadBytesResumable(storageRef, uri);
          uploadTask.on(
            "state_changed",
            snapshot => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case "paused":
                  console.log("Upload is paused");
                  break;
                case "running":
                  console.log("Upload is running");
                  break;
              }
            },
            error => {
              // Handle unsuccessful uploads
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                if (type === "main") {
                  formik.setValues({ ...formik.values, image: downloadURL });
                }
              });
            }
          );

        },
        'file' // output type
      );

    }
  };
  return (
    <Basic page={"Categories"}>
      <ToastContainer />
      <div>
        <div className="d-flex justify-content-between p-2 px-4">
          <div className="p-input-icon-left">
            <FcSearch />
            <InputText placeholder="Search" className="rounded-5" />
          </div>
          <div>
            <Button
              label="Add Category"
              severity="success"
              onClick={() => { setModalShow(true); formik.resetForm() }}
              raised
              rounded
              icon={<FcPlus size={20} />}
              iconPos="right"
            />
          </div>
        </div>
        <DataTable
          value={Categories}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column body={imageBodyTemplate} header="Image"></Column>
          <Column field="name" header="Name"></Column>
          <Column body={dateBodyTemplate} header="date"></Column>
          <Column body={actionTemplate} header="Actions"></Column>
        </DataTable>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setModalShow(false)}
        show={modalShow}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Category
          </Modal.Title>
        </Modal.Header>
        <Form className="" onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-5 col-12 d-flex flex-column align-items-center">
                {formik.values.image ? (
                  <img
                    width="200px"
                    height="200px"
                    className="text-center mt-2"
                    src={formik.values.image}
                  />
                ) : (
                  <img
                    width="200px"
                    height="200px"
                    className="text-center mt-2"
                    src={
                      "https://t3.ftcdn.net/jpg/05/52/37/18/360_F_552371867_LkVmqMEChRhMMHDQ2drOS8cwhAWehgVc.jpg"
                    }
                  />
                )}
                <Form.Control
                  type="file"
                  className="mt-2"
                  onChange={e => uploadImage([e.target.files[0]], "main")}
                  required
                />
              </div>
              <div className="col-sm-7 col-12 d-flex justify-content-center align-items-center">
                <div>
                  <div className="w-100">
                    <label htmlFor="Name">Name:</label>
                    <InputText
                      placeholder="Name"
                      id="name"
                      name="name"
                      className="rounded-5 w-100"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      required
                    />
                  </div>
                  <div className="w-100 mt-2">
                    <label htmlFor="name_ar">Name(عربية):</label>
                    <InputText
                      placeholder="name_ar"
                      id="name_ar"
                      name="name_ar"
                      className="rounded-5 w-100"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name_ar}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button
              label={!formik.values.id ? "Add" : "Update"}
              severity="success"
              type="submit"
              raised
              rounded
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </Basic>
  );
}

export default Categories;
