import { createStore, combineReducers, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import userReducer from "./user/user";
import categoriesReduce from "./categories/categories";
import productsReduce from "./products/products";
import ordersReduce from "./orders/orders";
import storeReduce from "./store/store";
const reducer = combineReducers({
  userReducer,
  categoriesReduce,
  productsReduce,
  ordersReduce,
  storeReduce
});

const store = createStore(reducer, applyMiddleware(logger, thunk));

export default store;
