import { Box, Button, Chip, Paper, TextField } from "@mui/material";
import Basic from "../../layouts/basic/Basic";
import { getAuth, updateProfile } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { useFormik } from 'formik';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import ImageCompressor from 'react-image-file-resizer';
import axios from 'axios';

function Settings() {
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const uploadImage = async (e) => {

    for (let i = 0; i < e.length; i++) {
      // Resize the image using ImageCompressor
      await ImageCompressor.imageFileResizer(
        e[i], // input file
        Infinity, // max width
        Infinity, // max height
        'WEBP', // output format
        80, // quality
        0, // rotation
        async (uri) => {
          const dataImage = new FormData();
          const blob = await fetch(uri).then((r) => r.blob());
          dataImage.append('upload', blob, new Date().getTime() + i + 'main');

          axios.post(`https://urchin-app-rniix.ondigitalocean.app/upload`, dataImage, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          })
            .then(function (response) {
              try {
                updateProfile(auth.currentUser, {
                  photoURL: response.data.url
                });
                console.log("User information updated successfully!");
              } catch (error) {
                console.error("Error updating user information:", error);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        },
        'base64' // output type
      );
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Call the provided callback function with the selected file
      // onFileUpload(file);
      uploadImage([file])
    }
  };
  const { t, i18n } = useTranslation();
  const auth = getAuth();

  const formik = useFormik({
    initialValues: {
      displayName: "",
      email: ""
    },
    onSubmit: async (values) => {

    },
  });

  const [isLoading,setIsLoading] = useState(false)

  useEffect(() => {
    if (!!auth?.currentUser?.displayName) {
      formik.setFieldValue("displayName", auth.currentUser.displayName)
      formik.setFieldValue("email", auth.currentUser.email)
      setIsLoading(true)
    }

  }, [auth]);

  return <Basic page="Settings">

    <div className="row flex-grow-1 m-0">
      <div className="col-sm-4 col-12">
        {isLoading ? <Paper elevation={3} className='p-2' >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              src={auth?.currentUser?.photoURL || "https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAxL3JtNjA5LXNvbGlkaWNvbi13LTAwMi1wLnBuZw.png"}
              style={{ width: "200px", height: "200px", borderRadius: "50%" }}
              className="mx-auto"
            />
            <div className="d-flex justify-content-start mt-2">
              <>
                <input
                  ref={fileInputRef}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <Button
                  className="mx-auto"
                  type="button"
                  onClick={handleButtonClick}
                  variant="contained"
                >
                  + Upload
                </Button>
              </>
            </div>
          </div>
          <Divider className="my-2" />
          <div className="">
            <Box className="d-flex justify-content-between">
              <p>{t("Full_Name")}:</p>
              <Chip label={auth.currentUser.displayName} />
            </Box>
            <Box className="d-flex justify-content-between">
              <p>{t("Email")}:</p>
              <Chip label={auth.currentUser.email} />
            </Box>
            <Box className="d-flex justify-content-between">
              <p>{t("Created_AT")}:</p>
              <Chip label={auth.currentUser.metadata.creationTime} />
            </Box>
            <Box className="d-flex justify-content-between">
              <p>{t("Last_Sign_In_Time_At")}:</p>
              <Chip label={
                auth.currentUser.metadata.lastSignInTime
              } />
            </Box>

          </div>
        </Paper> : "loading...."}
      </div>

    </div>

  </Basic >;
}

export default Settings;
