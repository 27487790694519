export const landingPageDemo = {
  "deleted": false,
  "brand_name":"_......_",
  "description": "<p><strong>STEP1:</strong></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit massa quis justo dapibus, nec fermentum quam consequat. Integer sed mi magna. Vivamus sit amet sem in odio eleifend ultrices.</p><p><br></p><p><img src=\"https://dokean.fra1.digitaloceanspaces.com/17123526770621.svg\"></p><p><strong>STEP2:</strong></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit massa quis justo dapibus, nec fermentum quam consequat. Integer sed mi magna. Vivamus sit amet sem in odio eleifend ultrices.</p><p><br></p><p><img src=\"https://dokean.fra1.digitaloceanspaces.com/17123526958702.svg\"></p><p><strong>STEP3:</strong></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit massa quis justo dapibus, nec fermentum quam consequat. Integer sed mi magna. Vivamus sit amet sem in odio eleifend ultrices.</p><p><br></p><p><img src=\"https://dokean.fra1.digitaloceanspaces.com/17123527116373.svg\"></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit massa quis justo dapibus, nec fermentum quam consequat. Integer sed mi magna. Vivamus sit amet sem in odio eleifend ultrices.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit massa quis justo dapibus, nec fermentum quam consequat. Integer sed mi magna. Vivamus sit amet sem in odio eleifend ultrices.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit massa quis justo dapibus, nec fermentum quam consequat. Integer sed mi magna. Vivamus sit amet sem in odio eleifend ultrices.</p>",
  "apis": {
    "google_sheet": "google sheet test",
    "facebook_pixel": "facebook pixel test"
  },
  "ceo": {
    "description": "SEO description meta for the store",
    "title": "SEO title for the store ",
    "logo": "https://dokean.fra1.digitaloceanspaces.com/17123525734681712352573249main"
  },
  "image": "https://dokean.fra1.digitaloceanspaces.com/17122412125351712241211865main",
  "options": [
    {
      "option": "colors",
      "values": [
      ]
    },
    {
      "values": [
      ],
      "option": "sizes"
    }
  ],
  "slider": [
    "https://dokean.fra1.digitaloceanspaces.com/17122652150811712265214567main",
    "https://dokean.fra1.digitaloceanspaces.com/17122652881111712265287711main",
    "https://dokean.fra1.digitaloceanspaces.com/17122652915001712265291131main",
    "https://dokean.fra1.digitaloceanspaces.com/17122652964301712265296654main",
    "https://dokean.fra1.digitaloceanspaces.com/17122653003761712265300041main"
  ],
  "socialMedia": {
    "phone": "0666554433",
    "facebook": "https://fb.com/",
    "tiktok": "https://www.tiktok.com/",
    "instagram": "https://www.instagram.com/"
  },
  "offers": [],
  "footer": "your brand © 2024",
  "price": "2900",
  "disable": false,
  "delevery": {
    "states": [
      {
        "name": "أدرار",
        "hide": true,
        "wilaya_code": "01",
        "wilaya_name_ascii": "Adrar",
        "price": "500"
      },
      {
        "hide": true,
        "price": "500",
        "name": "الشلف",
        "wilaya_name_ascii": "Chlef",
        "wilaya_code": "02"
      },
      {
        "price": 500,
        "name": "الأغواط",
        "wilaya_name_ascii": "Laghouat",
        "wilaya_code": "03",
        "hide": true
      },
      {
        "price": 600,
        "name": "أم البواقي",
        "wilaya_name_ascii": "Oum El Bouaghi",
        "hide": true,
        "wilaya_code": "04"
      },
      {
        "name": "باتنة",
        "wilaya_code": "05",
        "wilaya_name_ascii": "Batna",
        "hide": false,
        "price": 5
      },
      {
        "name": "بجاية",
        "hide": true,
        "wilaya_code": "06",
        "wilaya_name_ascii": "Béjaïa",
        "price": 100
      },
      {
        "name": "بسكرة",
        "wilaya_code": "07",
        "wilaya_name_ascii": "Biskra",
        "hide": false,
        "price": "300"
      },
      {
        "name": "البليدة",
        "wilaya_code": "09",
        "wilaya_name_ascii": "Blida",
        "hide": false,
        "price": 0
      },
      {
        "price": 100,
        "hide": false,
        "name": "بشار",
        "wilaya_code": "08",
        "wilaya_name_ascii": "Béchar",
      },
      {
        "hide": false,
        "wilaya_code": "10",
        "price": 100,
        "wilaya_name_ascii": "Bouira",
        "name": "البويرة"
      },
      {
        "price": 100,
        "name": "تمنراست",
        "wilaya_code": "11",
        "wilaya_name_ascii": "Tamanrasset",
        "hide": false
      },
      {
        "hide": false,
        "wilaya_code": "12",
        "price": 100,
        "wilaya_name_ascii": "Tébessa",
        "name": "تبسة"
      },
      {
        "name": "تلمسان",
        "hide": false,
        "wilaya_code": "13",
        "wilaya_name_ascii": "Tlemcen",
        "price": 100
      },
      {
        "hide": false,
        "name": "تيارت",
        "wilaya_name_ascii": "Tiaret",
        "wilaya_code": "14",
        "price": 100
      },
      {
        "price": 100,
        "hide": false,
        "wilaya_code": "15",
        "wilaya_name_ascii": "Tizi Ouzou",
        "name": "تيزي وزو"
      },
      {
        "name": "الجزائر",
        "price": 100,
        "wilaya_code": "16",
        "wilaya_name_ascii": "Alger",
        "hide": false
      },
      {
        "hide": false,
        "name": "الجلفة",
        "wilaya_code": "17",
        "wilaya_name_ascii": "Djelfa",
        "price": 100
      },
      {
        "wilaya_code": "18",
        "name": "جيجل",
        "wilaya_name_ascii": "Jijel",
        "hide": false,
        "price": 100
      },
      {
        "price": 100,
        "name": "سطيف",
        "wilaya_code": "19",
        "wilaya_name_ascii": "Sétif",
        "hide": false
      },
      {
        "wilaya_code": "20",
        "hide": false,
        "price": 100,
        "wilaya_name_ascii": "Saïda",
        "name": "سعيدة"
      },
      {
        "wilaya_code": "21",
        "name": "سكيكدة",
        "wilaya_name_ascii": "Skikda",
        "hide": false,
        "price": 100
      },
      {
        "hide": false,
        "wilaya_code": "22",
        "price": 100,
        "wilaya_name_ascii": "Sidi Bel Abbès",
        "name": "سيدي بلعباس"
      },
      {
        "name": "عنابة",
        "wilaya_code": "23",
        "wilaya_name_ascii": "Annaba",
        "hide": false,
        "price": 100
      },
      {
        "name": "قالمة",
        "price": 100,
        "hide": false,
        "wilaya_name_ascii": "Guelma",
        "wilaya_code": "24"
      },
      {
        "wilaya_code": "25",
        "hide": false,
        "price": 100,
        "wilaya_name_ascii": "Constantine",
        "name": "قسنطينة"
      },
      {
        "price": 100,
        "name": "المدية",
        "wilaya_name_ascii": "Médéa",
        "hide": false,
        "wilaya_code": "26"
      },
      {
        "name": "مستغانم",
        "price": 100,
        "wilaya_code": "27",
        "wilaya_name_ascii": "Mostaganem",
        "hide": false
      },
      {
        "wilaya_code": "28",
        "name": "المسيلة",
        "wilaya_name_ascii": "M'Sila",
        "price": 100,
        "hide": false
      },
      {
        "name": "معسكر",
        "price": 100,
        "wilaya_code": "29",
        "wilaya_name_ascii": "Mascara",
        "hide": false
      },
      {
        "hide": false,
        "name": "ورقلة",
        "price": 100,
        "wilaya_name_ascii": "Ouargla",
        "wilaya_code": "30"
      },
      {
        "wilaya_code": "31",
        "name": "وهران",
        "wilaya_name_ascii": "Oran",
        "price": 100,
        "hide": false
      },
      {
        "hide": false,
        "name": "البيض",
        "wilaya_name_ascii": "El Bayadh",
        "wilaya_code": "32",
        "price": 100
      },
      {
        "hide": false,
        "wilaya_code": "33",
        "price": 100,
        "wilaya_name_ascii": "Illizi",
        "name": "إليزي"
      },
      {
        "name": "برج بوعريريج",
        "hide": false,
        "wilaya_name_ascii": "Bordj Bou Arreridj",
        "wilaya_code": "34",
        "price": 100
      },
      {
        "price": 100,
        "wilaya_code": "35",
        "wilaya_name_ascii": "Boumerdès",
        "name": "بومرداس",
        "hide": false
      },
      {
        "wilaya_code": "36",
        "hide": false,
        "wilaya_name_ascii": "El Tarf",
        "name": "الطارف",
        "price": 100
      },
      {
        "price": 100,
        "wilaya_code": "37",
        "wilaya_name_ascii": "Tindouf",
        "hide": false,
        "name": "تندوف"
      },
      {
        "name": "تيسمسيلت",
        "wilaya_name_ascii": "Tissemsilt",
        "hide": false,
        "price": 100,
        "wilaya_code": "38"
      },
      {
        "price": 100,
        "name": "الوادي",
        "wilaya_name_ascii": "El Oued",
        "wilaya_code": "39",
        "hide": false
      },
      {
        "hide": false,
        "price": 100,
        "name": "خنشلة",
        "wilaya_name_ascii": "Khenchela",
        "wilaya_code": "40"
      },
      {
        "name": "سوق أهراس",
        "wilaya_code": "41",
        "wilaya_name_ascii": "Souk Ahras",
        "hide": false,
        "price": 100
      },
      {
        "wilaya_code": "42",
        "name": "تيبازة",
        "wilaya_name_ascii": "Tipaza",
        "price": 100,
        "hide": false
      },
      {
        "wilaya_code": "43",
        "hide": false,
        "name": "ميلة",
        "wilaya_name_ascii": "Mila",
        "price": 100
      },
      {
        "wilaya_code": "44",
        "hide": false,
        "price": 100,
        "wilaya_name_ascii": "Aïn Defla",
        "name": "عين الدفلة"
      },
      {
        "wilaya_code": "45",
        "hide": false,
        "name": "النعامة",
        "wilaya_name_ascii": "Naâma",
        "price": 100
      },
      {
        "hide": false,
        "name": "عين تيموشنت",
        "wilaya_name_ascii": "Aïn Témouchent",
        "price": 100,
        "wilaya_code": "46"
      },
      {
        "price": 100,
        "hide": false,
        "name": "غرداية",
        "wilaya_name_ascii": "Ghardaïa",
        "wilaya_code": "47"
      },
      {
        "price": 100,
        "hide": false,
        "wilaya_code": "48",
        "wilaya_name_ascii": "Relizane",
        "name": "غليزان"
      },
      {
        "wilaya_code": "49",
        "hide": false,
        "name": "تيميمون",
        "wilaya_name_ascii": "Timimoun",
        "price": 100
      },
      {
        "hide": false,
        "price": 100,
        "wilaya_code": "50",
        "wilaya_name_ascii": "Bordj Badji Mokhtar",
        "name": "برج باجي مختار"
      },
      {
        "name": "أولاد جلال",
        "wilaya_code": "51",
        "wilaya_name_ascii": "Ouled Djellal",
        "hide": false,
        "price": 100
      },
      {
        "price": 100,
        "name": "بني عباس",
        "wilaya_name_ascii": "Béni Abbès",
        "hide": false,
        "wilaya_code": "52"
      },
      {
        "hide": false,
        "wilaya_code": "53",
        "wilaya_name_ascii": "In Salah",
        "price": 100,
        "name": "عين صالح"
      },
      {
        "name": "عين قزام",
        "wilaya_code": "54",
        "wilaya_name_ascii": "In Guezzam",
        "hide": false,
        "price": 100
      },
      {
        "price": 100,
        "wilaya_code": "55",
        "wilaya_name_ascii": "Touggourt",
        "name": "تقرت",
        "hide": false
      },
      {
        "hide": false,
        "name": "جانت",
        "wilaya_name_ascii": "Djanet",
        "wilaya_code": "56",
        "price": 100
      },
      {
        "price": 100,
        "hide": false,
        "name": "المغير",
        "wilaya_name_ascii": "El Meghaier",
        "wilaya_code": "57"
      },
      {
        "hide": false,
        "wilaya_code": "58",
        "price": 100,
        "wilaya_name_ascii": "El Menia",
        "name": "المنيعة"
      }
    ],
    "activeCities": true,
    "delevery_home": false,
    "home_price": 300,
    "paid": false,
    "free": true,
    "address": true
  },
  "discount": 20,
  "bio": "this is a bio for this product (short description here extra data)",
  "review": 5,
  "stock": {
    "alert_qty": 0,
    "qty": 0
  },
  "title": "product Name",
  "body": {
    "color1": "#fafafa",
    "color3": "#2a3e50",
    "color2": "#a7c8b8"
  },
  "dir": "ltr",
  "currency": "D.A"
}