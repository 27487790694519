import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch } from "react-redux";
import { signOut, getAuth } from "firebase/auth";
import { logoutUser } from "../../redux/user/user";
import { Link, useLocation } from "react-router-dom";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { Divider } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import SettingsIcon from "@mui/icons-material/Settings";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SchoolIcon from "@mui/icons-material/School";
import { useTranslation } from 'react-i18next';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useSelector } from 'react-redux';

function NavBar1() {
  const { t, i18n } = useTranslation();
  const store = useSelector((state) => state.storeReduce);

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const auth = getAuth();

  const handleSLogout = () => {
    signOut(auth)
      .then(() => {
        dispatch(logoutUser(""));
        localStorage.setItem("token", "");
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out:", error);
      });
  };
  return (
    <>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="fixed-top"
          style={{
            height: "60px",
            backgroundColor: "#2C3E50",
            borderBottom: "2px solid #2C3E50",
          }}

        >
          <Container fluid >
            <Navbar.Brand href="#">
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/eco-landing-79b1b.appspot.com/o/Capture-removebg-preview.png?alt=media&token=e532b9d5-5139-4117-a9b9-08b585e7ec69"
                }
                alt={"logo"}
                className=" rounded rounded-3"
                height={30}
              />
            </Navbar.Brand>
            <div className="d-flex align-items-center">
              <b className="me-2 text-white">{store.balance}</b>
              <div className="coin mt-1" style={{width:"15px",height:"15px"}}>
                <div className="coin-face">
                  <div className="coin-symbol">D</div>
                </div>
              </div>

            </div>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} style={{ color: "#fff", borderColor: "#fff" }}>
              <WidgetsIcon  />
            </Navbar.Toggle>

          </Container>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            style={{ width: "1000vw" }}

          >
            <Offcanvas.Header closeButton style={{ color: "#2C3E50" }} dir={i18n.language === "ar" ? "rtl" : ""}>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                {t('menu')}
              </Offcanvas.Title>
              <div className="d-flex">
                <div

                  className="rounded rounded-circle me-1 d-flex justify-content-center align-items-center"
                  style={{
                    height: "35px",
                    width: "35px",
                    backgroundColor: i18n.language === "en" ? "#2C3E50" : "white",
                    position: 'relative',
                    border: "2px solid #2C3E50"
                  }}
                  onClick={() => { handleChangeLanguage("en") }}
                >
                  <span style={{ color: i18n.language === "en" ? "white" : "#2C3E50" }}>EN</span>
                </div>
                <div

                  className="rounded rounded-circle me-1 d-flex justify-content-center align-items-center"
                  style={{
                    height: "35px",
                    width: "35px",
                    backgroundColor: i18n.language === "fr" ? "#2C3E50" : "white",
                    position: 'relative',
                    border: "2px solid #2C3E50"
                  }}
                  onClick={() => { handleChangeLanguage("fr") }}
                >
                  <span style={{ color: i18n.language === "fr" ? "white" : "#2C3E50" }}>FR</span>
                </div>
                <div

                  className="rounded rounded-circle me-1 d-flex justify-content-center align-items-center"
                  style={{
                    height: "35px",
                    width: "35px",
                    backgroundColor: i18n.language === "ar" ? "#2C3E50" : "white",
                    position: 'relative',
                    border: "2px solid #2C3E50"
                  }}
                  onClick={() => { handleChangeLanguage("ar") }}
                >
                  <span className="fw-blod" style={{ marginTop: "-5px", color: i18n.language === "ar" ? "white" : "#2C3E50" }}>ع</span>
                </div>
              </div>

            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column" dir={i18n.language === "ar" ? "rtl" : ""}>
              <div className="col-sm-8 col-12 mx-auto">
                <Divider />
              </div>
              <div className="flex-grow-1 fw-bolder">
                <div className="row col-sm-8 col-12 mx-auto">
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to={`/home`}
                    className="col-sm-2 col-4 my-1"
                  >
                    <div className="text-center">
                      <div
                        style={{

                          backgroundColor:
                            location.pathname !== "/home"
                              ? "#ECF0F1"
                              : "#2C3E50",
                        }}
                        className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3`}
                      >
                        <HomeIcon
                          style={{
                            width: "100px",
                            height: "100px",
                            color:
                              location.pathname !== "/home"
                                ? "#2C3E50"
                                : "white",
                          }}
                        />
                      </div>
                      {t('home')}
                    </div>
                  </Link>
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to={`/Landings`}
                    className="col-sm-2 col-4 my-1"
                  >
                    <div className="text-center">
                      <div
                        style={{
                          backgroundColor:
                            location.pathname !== "/Landings"
                              ? "#ECF0F1"
                              : "#2C3E50",
                        }}
                        className={`mx-auto my-2 d-flex align-items-center border border-2 justify-content-center rounded-3`}
                      >
                        <InventoryIcon
                          style={{
                            width: "100px",
                            height: "100px",
                            color:
                              location.pathname !== "/Landings"
                                ? "#2C3E50"
                                : "white",
                          }}
                        />
                      </div>

                      {t('landing_page')}
                    </div>
                  </Link>
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to={`/settings`}
                    className="col-sm-2 col-4 my-1"
                  >
                    <div className="text-center">
                      <div
                        style={{

                          backgroundColor:
                            location.pathname !== "/settings"
                              ? "#ECF0F1"
                              : "#2C3E50",
                        }}
                        className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3`}
                      >
                        <SettingsIcon
                          style={{
                            width: "100px",
                            height: "100px",
                            color:
                              location.pathname !== "/settings"
                                ? "#2C3E50"
                                : "white",
                          }}
                        />
                      </div>

                      {t('settings')}
                    </div>
                  </Link>
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to={`/Shipping`}
                    className="col-sm-2 col-4 my-1"
                  >
                    <div className="text-center">
                      <div
                        style={{

                          backgroundColor:
                            location.pathname !== "/Shipping"
                              ? "#ECF0F1"
                              : "#2C3E50",
                        }}
                        className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3`}
                      >
                        <LocalShippingIcon
                          style={{
                            width: "100px",
                            height: "100px",
                            color:
                              location.pathname !== "/Shipping"
                                ? "#2C3E50"
                                : "white",
                          }}
                        />
                      </div>

                      {t('shipping')}
                    </div>
                  </Link>
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to={`/Subscription`}
                    className="col-sm-2 col-4 my-1"
                  >
                    <div className="text-center">
                      <div
                        style={{

                          backgroundColor:
                            location.pathname !== "/Subscription"
                              ? "#ECF0F1"
                              : "#2C3E50",
                        }}
                        className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3`}
                      >

                        <NotificationsActiveIcon
                          style={{
                            width: "100px",
                            height: "100px",
                            color:
                              location.pathname !== "/Subscription"
                                ? "#2C3E50"
                                : "white",
                          }}
                        />
                      </div>

                      {t('subscription')}
                    </div>
                  </Link>
                </div>

              </div>

              <div className="row col-sm-8 col-12 fw-bolder mx-auto mt-4">
                <hr />
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to={`/Center`}
                  className="col-sm-2 col-4"
                >
                  <div className="text-center">
                    <div
                      style={{

                        backgroundColor:
                          location.pathname !== "/Center"
                            ? "#ECF0F1"
                            : "#2C3E50",
                      }}
                      className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3`}
                    >
                      <SchoolIcon
                        style={{
                          width: "100px",
                          height: "100px",
                          color:
                            location.pathname !== "/Center"
                              ? "#2C3E50"
                              : "white",
                        }}
                      />
                    </div>
                    {t('learning_center')}
                  </div>
                </Link>
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to={`/Support`}
                  className="col-sm-2 col-4"
                >
                  <div className="text-center">
                    <div
                      style={{

                        backgroundColor:
                          location.pathname !== "/Support"
                            ? "#ECF0F1"
                            : "#2C3E50",
                      }}
                      className={`mx-auto my-2  d-flex align-items-center border border-2 justify-content-center rounded-3`}
                    >
                      <SupportAgentIcon
                        style={{
                          width: "100px",
                          height: "100px",
                          color:
                            location.pathname !== "/Support"
                              ? "#2C3E50"
                              : "white",
                        }}
                      />
                    </div>
                    {t('support')}

                  </div>
                </Link>
                <div
                  style={{ color: "inherit", textDecoration: "none" }}
                  className="col-sm-2 col-4"
                  onClick={handleSLogout}
                >
                  <div className="text-center ">
                    <div

                      className={`mx-auto my-2 bg-danger  d-flex align-items-center border border-2 justify-content-center rounded-3`}
                    >
                      <PowerSettingsNewIcon
                        style={{
                          color: "white",
                          width: "100px",
                          height: "100px",
                        }}
                      />
                    </div>

                    {t('log_out')}
                  </div>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      ))}
    </>
  );
}

export default NavBar1;
