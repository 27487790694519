import { Button, TextField, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { db } from "../../../firebase";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next';
import {  toast } from "react-toastify";

function Delevery({ toggleDrawer, dataToBeEdited }) {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(undefined);
  const formik = useFormik({
    initialValues: {
      states: [],
      free: false,
      activeCities: true,
      home_delevery: {
        is: false,
        value: 0
      }
    },
    onSubmit: async (values) => {
      await setDoc(doc(db, "landing-pages", dataToBeEdited.id), {
        ...data,
        delevery: {
          ...data.delevery,
          states: values.states,
          free: values.free,
          activeCities: values.activeCities,
          delevery_home: values.home_delevery.is,
          home_price: values.home_delevery.value
        }
      }).then(() => {
        notify(t("Update_Completed_Successfully"))
      });
    },
  });

  const [find, setFind] = useState("");

  useEffect(() => {
    // Function to fetch data from an API
    onSnapshot(doc(db, "landing-pages", dataToBeEdited.id), (doc) => {
      formik.setValues({
        states: doc.data().delevery.states,
        free: doc.data().delevery.free,
        activeCities: doc.data().delevery.activeCities,
        home_delevery: {
          is: doc.data().delevery.delevery_home,
          value: doc.data().delevery.home_price
        }
      })
      setData({
        id: dataToBeEdited.id,
        ...doc.data()
      })

    });
  }, []);

  const notify = async (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <div className="d-flex flex-column" dir={i18n.language === "ar" ? "rtl" : ""} style={{ minHeight: "100vh" }}>
      <div className="mb-2 d-flex justify-content-between align-items-center" style={{
        minHeight: "30px",
        backgroundColor: "#ECF0F1",
        borderBottom: "2px solid #2C3E50",
      }}>
        <ArrowBackIcon onClick={() => { toggleDrawer(false) }} />
        <p className="m-0 p-0 px-2">{t('Location')}</p>
      </div>
      <div className="flex-grow-1 d-flex flex-column">
        <Accordion className="m-2">
          <AccordionSummary
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="page-Design"
            id="Page-Design"
          >
            <div className="d-flex justify-content-between p-2 w-100" >
              <div className="d-flex align-items-center">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder={t('Search')}
                  value={find}
                  onChange={(e) => { setFind(e.target.value) }}
                />

              </div>


            </div>
          </AccordionSummary>
          <AccordionDetails>
          <Form onSubmit={formik.handleSubmit}>
            <hr />
            
            <div>
              <FormControlLabel
                control={<Switch checked={formik.values.free} onChange={() => { formik.setFieldValue("free", !formik.values.free) }} />}
                label={t('Free_Shipping')}
                className="mx-2"
              />
            </div>
            <div>
              {formik.values.free && <>
                <FormControlLabel
                  control={<Switch checked={formik.values.home_delevery.is} onChange={() => {
                    formik.setFieldValue("home_delevery", {
                      ...formik.values.home_delevery,
                      is: !formik.values.home_delevery.is
                    })
                  }} />}
                  label={t('Home_Delivery')}
                  className="mx-2"
                />
                {formik.values.home_delevery.is && <TextField
                  value={formik.values.home_delevery.value}
                  onChange={(e) => {
                    formik.setFieldValue("home_delevery", {
                      ...formik.values.home_delevery,
                      value: e.target.value
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  placeholder="Extra on home Delevery"
                  type="number"
                />}
              </>}
            </div>
            <div>
              <FormControlLabel
                control={<Switch checked={formik.values.activeCities} onChange={() => { formik.setFieldValue("activeCities", !formik.values.activeCities) }} />}
                label={t('Active_Cities')}
                className="mx-2"
              />
            </div>
            
            
            <hr />
            <div className="text-end">
              <Button type="submit" variant="contained" className="bg-success">
                {t('Update')}
              </Button>
            </div>
            </Form>
          </AccordionDetails>
        </Accordion>

        <div className="row p-0 m-0 py-2">
          {formik.values.free && formik.values.states.filter(s => {
            return s.wilaya_code.includes(find);
          }).map((s) => (
            <div className="col-6 col-sm-2 py-2" key={s.wilaya_code}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={s.price}
                label={s.wilaya_code + " " + s.name}
                type="number"
                onChange={(e) => {
                  const updatedStates = formik.values.states.map(state => {
                    if (state.wilaya_code === s.wilaya_code) {
                      return { ...state, price: parseInt(e.target.value) };
                    }
                    return state;
                  });
                  formik.setFieldValue("states", updatedStates);
                }}
                InputProps={{
                  startAdornment: (
                    <Checkbox checked={!s.hide} className="m-0 p-0" onClick={() => {
                      const updatedStates = formik.values.states.map(state => {
                        if (state.wilaya_code === s.wilaya_code) {
                          return { ...state, hide: !state.hide };
                        }
                        return state;
                      });
                      formik.setFieldValue("states", updatedStates);
                    }} />
                  ),
                }}
              />
            </div>
          ))}
        </div>
      </div>

    </div>
  );
}

export default Delevery;
